import styled from 'styled-components';
import React from 'react';

const BitcoinIcon = styled((props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="114" height="114" viewBox="0 0 114 114" fill="none">
    <path
      d="M112.294 70.7871C104.68 101.329 73.7481 119.908 43.2048 112.294C12.6768 104.681 -5.91039 73.7529 1.70796 43.2175C9.31757 12.6777 40.2474 -5.90734 70.782 1.70576C101.323 9.31887 119.908 40.2517 112.294 70.7871Z"
      fill="#222625"
    />
    <path
      d="M82.1352 48.8801C83.271 41.2954 77.4939 37.2181 69.5982 34.4991L72.1602 24.2238L65.9048 22.6645L63.4104 32.669C61.7439 32.2606 60.0752 31.8675 58.4 31.4897L60.9118 21.4197L54.6585 19.8604L52.0965 30.1313C50.7358 29.8212 49.3991 29.5154 48.1017 29.1922L48.1082 29.1594L39.483 27.0061L37.8186 33.6867C37.8186 33.6867 42.46 34.7502 42.3617 34.8158C44.8953 35.4491 45.3518 37.1241 45.2754 38.4541L42.3573 50.1577C42.5321 50.2014 42.7592 50.2691 43.0082 50.3652L42.3464 50.2014L38.2555 66.5982C37.9453 67.3669 37.159 68.5222 35.3877 68.0832C35.451 68.1749 30.8424 66.9476 30.8424 66.9476L27.7366 74.1086L35.8769 76.1397C37.3906 76.5175 38.8758 76.9149 40.337 77.2884L37.7488 87.6794L43.9955 89.2387L46.5575 78.9547C48.2633 79.4199 49.9211 79.8458 51.5417 80.2498L48.9863 90.4836L55.2395 92.0429L57.8299 81.6715C68.4952 83.6894 76.5154 82.8748 79.8921 73.2329C82.6114 65.4669 79.7567 60.9877 74.1456 58.0635C78.2321 57.12 81.3096 54.4316 82.1309 48.8801H82.1352ZM67.8421 68.9153C65.9091 76.6813 52.8326 72.486 48.5909 71.4312L52.0266 57.6638C56.2661 58.723 69.8624 60.8174 67.8421 68.9153ZM69.7773 48.7687C68.0147 55.8315 57.1288 52.2412 53.597 51.3632L56.7116 38.8756C60.2434 39.7558 71.6141 41.3981 69.7773 48.7666V48.7687Z"
      fill="white"
    />
  </svg>
))`
  margin-right: 30px;
  flex-shrink: 0;
`;

export default BitcoinIcon;

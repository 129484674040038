import * as React from 'react';
import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  width: 150px;
  height: 113px;
  margin-left: 20px;
  text-align: center;
  flex-shrink: 0;
`;

const aboveWaveAnimation = keyframes`
  0% { opacity: 0; }
  25% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 1; }
  100% { opacity: 0; }
`;

const belowWaveAnimation = keyframes`
  0% { opacity: 0; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 0; }
`;

const WaveAbove = styled.g`
  animation: ${aboveWaveAnimation} 1s infinite;
`;

const WaveBelow = styled.g`
  animation: ${belowWaveAnimation} 1s infinite;
`;

const UploadingIcon = styled((props) => (
  <svg width={592} height={607} viewBox="0 0 592 607" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2702_144)">
      <path
        d="M549.76 149.37C519.06 118.66 477.33 105.83 437.32 110.65C409.91 48.6601 347.91 5.38013 275.78 5.38013C182.8 5.38013 106.66 77.2401 99.7503 168.45C75.7203 170.6 52.2903 180.86 33.8903 199.25C-7.77969 240.92 -7.78969 308.49 33.8803 350.16C53.4403 369.7 78.6803 379.97 104.26 381.18L462.54 381C494.31 378.93 525.49 365.89 549.77 341.61C602.87 288.53 602.87 202.46 549.77 149.37H549.76Z"
        fill="#EBEBEE"
      />
      <path
        d="M549.34 149.79C520.63 120.65 477.96 106.63 437.57 112.66C436.66 112.79 435.82 112.27 435.48 111.47C419.03 76.57 390.89 47.78 357.08 30.51C247.96 -26.19 112.33 44.81 101.69 168.6C101.62 169.56 100.85 170.31 99.92 170.39C79.72 172.08 60.03 179.79 44.24 192.54C-6.10997 232.11 -7.75997 309.39 40.75 351.06C59.48 367.33 84.05 376.48 108.75 376.07C199.33 375.28 312.3 374.33 402.44 374.71C418.23 374.76 444.77 374.93 460.41 375.03L462.34 375.05L464.12 374.91L467.69 374.64C483.66 373.01 499.41 368.64 513.81 361.51C594.49 321.77 613.29 213.76 549.33 149.79H549.34ZM550.19 148.94C607.47 205.92 604.66 300.79 543.34 353.76C525.58 369.07 503.88 379.75 480.9 384.45C475.08 385.65 468.68 386.46 462.74 386.95C461.7 386.93 458.07 387.02 456.93 387.01C346.45 388.12 219.43 387.09 108.52 386.29C70.78 386.33 34.18 364.79 15.27 332.25C-19.06 274.86 6.89003 198.42 69.08 173.92C78.82 170.01 89.12 167.56 99.57 166.51L97.8 168.3C98.77 154.79 101.16 141.33 105.07 128.35C145.49 -5.35997 314.16 -45.19 407.84 59.7C420.98 74.53 431.56 91.59 439.18 109.83L437.09 108.64C478.35 104.37 521 119.48 550.19 148.94Z"
        fill="black"
      />
      <path
        d="M222.749 243.01H249.139V313.36C249.139 322.3 256.379 329.54 265.299 329.54H315.549C324.489 329.54 331.719 322.29 331.719 313.36V243.01H360.809C377.299 243.01 386.599 224.08 376.539 211.02L316.429 142.47C303.959 126.29 279.599 126.29 267.119 142.47L206.999 211.02C196.939 224.09 206.249 243.01 222.739 243.01H222.749Z"
        fill="#6E7EC3"
      />
      <path
        d="M222.75 242.61C231.76 242.61 240.78 242.53 249.79 242.33C250.24 262.7 251.3 283.07 252 303.44C252.11 306.57 252.22 309.75 252.28 312.88C252.32 314.41 252.55 315.99 253.09 317.42C254.46 321.17 257.6 324.18 261.4 325.36C263.34 326.01 265.01 325.97 267.77 325.93H275.32C287.82 325.96 300.58 326.17 313.09 326.53C322.02 327.6 329.37 321.89 329.6 312.88C330.36 289.61 330.66 266.27 330.43 243L330.42 241.73C338.29 241.61 346.16 241.55 354.03 241.35C355.55 241.3 360.72 241.25 362.26 241.11C366.41 240.69 370.44 238.75 373.25 235.7C378.94 229.8 379.76 219.9 374.65 213.45C372.45 210.8 365.93 203.9 363.51 201.19C349.82 186 324.88 158.28 311.53 143.25C309.96 141.41 307.93 139.99 305.98 138.62L303.87 137.47C293.68 132.31 280.76 134.36 272.66 142.39C254.12 163.36 227.84 191.11 208.2 211.58C197.7 223.63 206.81 242.74 222.75 242.6V242.61ZM222.75 243.41C205.67 243.55 195.88 223.55 206.23 209.96C222.89 189.63 245.74 160.52 262.91 141.1C264.03 139.91 265.65 137.79 266.81 136.6L269.13 134.46L269.71 133.93C276.96 128.1 286.35 125.25 295.62 126.27C302.93 127.14 310.11 130.24 315.51 135.28C317.05 136.37 323.72 144.61 325.16 146.15C334.36 156.79 359.21 185.89 368.67 196.97C371.18 200.09 377.13 206.81 379.46 209.98C385.66 218.77 384.17 231.57 376.36 238.82C371.93 243.15 365.7 245.15 359.61 244.77C350.36 244.54 340.96 244.41 331.72 244.29L333.01 243C332.78 266.27 333.08 289.61 333.84 312.88C334.08 317.92 332.24 322.95 328.75 326.62C325.72 329.78 321.5 331.91 317.11 332.35C314.25 332.58 308.41 332.61 305.55 332.72C295.48 332.96 285.41 333.1 275.33 333.12C271.41 333 265.37 333.45 261.43 332.69C253.08 331.05 246.49 323.52 246.04 315.03C246.36 291.01 248.03 267 248.48 242.99L249.15 243.66C240.35 243.47 231.56 243.39 222.76 243.39L222.75 243.41Z"
        fill="black"
      />
      <WaveAbove>
        <path
          d="M164.719 88.7401C162.499 84.4101 208.679 40.0201 270.539 36.6301C343.259 32.6501 400.909 87.3401 398.739 91.6201C396.779 95.4801 347.019 56.3201 273.739 58.3701C209.279 60.1801 166.779 92.7601 164.729 88.7401H164.719Z"
          fill="#6E7EC3"
        />
        <path
          d="M164.46 88.87C163.67 85.8 172.04 78.45 174.28 76.25C190.26 61.95 208.81 50.31 228.94 42.73C259.53 30.93 293.56 31.76 324.28 42.57C349.6 51.34 373.69 64.22 393.72 82.21C395.9 84.36 404.3 91.81 398.64 93.65C396.31 93.83 394.77 92.74 392.8 91.99C387.86 89.74 383.15 87.36 378.33 85.13C359.22 76.16 339.23 68.98 318.47 65.13C271.73 56.07 222.49 63.44 179.5 83.55L172.25 86.94C170.08 87.86 167.97 89.06 165.6 89.38C165.19 89.4 164.68 89.31 164.45 88.88L164.46 88.87ZM164.96 88.61C165.03 88.74 165.28 88.8 165.58 88.79C166.54 88.69 167.44 88.31 168.35 87.95C172.02 86.38 175.57 84.55 179.15 82.79C193.51 75.59 208.16 68.98 223.55 64.16C275.68 47.07 332.33 56.02 380.34 81.04C386.15 83.87 392.48 87.83 398 90.08C397.9 90.08 397.31 90.3 397.1 90.82C396.97 91.01 396.97 91.2401 396.99 91.41C396.99 91.41 396.99 91.36 396.96 91.28C395.73 88.73 393.58 86.61 391.55 84.4C387.89 80.65 383.88 77.18 379.75 73.89C332.74 36.32 270.64 28.6 215.59 52.44C200.99 58.54 186.88 66.47 174.79 76.81C171.8 79.42 168.9 82.19 166.49 85.34C165.91 86.12 165.35 86.93 165.03 87.83C164.94 88.11 164.88 88.43 164.96 88.59V88.61Z"
          fill="black"
        />
      </WaveAbove>
      <WaveBelow>
        <path
          d="M202.99 101.46C202.02 98.7401 234.52 76.6601 276.06 75.3201C323.69 73.7801 361.69 100.2 360.67 102.99C359.75 105.51 327.21 87.4901 277.64 88.1401C233.13 88.7201 203.89 103.99 202.99 101.47V101.46Z"
          fill="#6E7EC3"
        />
        <path
          d="M202.82 101.52C202.69 100.93 203.09 100.6 203.36 100.19C205.38 97.89 208.01 96.25 210.52 94.55C219.3 88.92 228.73 84.38 238.55 80.82C264.99 70.8 294.12 72.07 320.7 80.95C333.91 85.32 346.89 90.57 358.31 98.74C359.51 99.72 360.85 100.63 361.63 102.08C361.82 102.49 361.91 103.01 361.7 103.46C361.49 103.91 361.16 104.15 360.74 104.28L360.53 104.34L360.38 104.36C358.34 104.4 356.72 103.67 354.84 103.17L344.95 100.1C302.29 86.83 255.82 86.88 213 99.5L208.03 100.99C206.55 101.37 205.08 101.92 203.53 101.93C203.31 101.9 202.98 101.88 202.82 101.53V101.52ZM203.17 101.39C203.33 101.62 203.88 101.53 204.16 101.5C207.12 100.95 209.96 99.86 212.83 98.96C222.7 95.68 232.63 92.58 242.82 90.34C276.9 82.58 312.96 85.96 345.86 97.26C350.65 98.71 355.29 100.97 360.13 102.06C360.08 102.08 359.98 102.12 359.9 102.18C359.73 102.28 359.53 102.65 359.57 102.88C359.6 103.1 359.59 102.94 359.52 102.85C356.63 99.33 352.49 96.97 348.66 94.5C316.07 74.68 275.42 72.52 239.29 83.06C229.42 85.93 219.65 89.65 210.81 94.99C208.28 96.58 205.7 98.22 203.68 100.44C203.55 100.6 203.42 100.77 203.33 100.94C203.23 101.11 203.16 101.31 203.2 101.4L203.17 101.39Z"
          fill="black"
        />
      </WaveBelow>
      <path
        d="M532.409 418.47H41.3994C34.772 418.47 29.3994 423.843 29.3994 430.47V482.71C29.3994 489.337 34.772 494.71 41.3994 494.71H532.409C539.037 494.71 544.409 489.337 544.409 482.71V430.47C544.409 423.843 539.037 418.47 532.409 418.47Z"
        fill="#EBEBEE"
      />
      <path
        d="M532.41 495.3C484.97 495.14 434.75 496.92 387.18 498.52C278.25 503.03 169.35 496.45 60.4198 496.52L42.2698 496.59C38.9398 496.74 35.2098 495.69 32.5998 493.48C29.8598 491.29 27.9698 488.02 27.4998 484.5C27.1598 481.48 27.3998 478.09 27.2698 475.17C27.1998 469.43 26.9898 453.88 26.8598 447.94C26.7998 442.61 26.5598 434.92 26.5498 429.62C26.7398 421.86 33.5898 415.19 41.3998 415.18C161.28 412.47 284.59 411.25 404.47 412.65C445.53 413.22 490.43 414.97 531.54 416.33C538.56 416.04 545.34 421.6 545.92 428.72C546.18 431.66 545.77 435.14 545.8 438C545.7 443.74 545.36 459.28 545.29 465.23C545.18 471.3 545.2 477.29 545.06 483.42C544.75 489.92 538.93 495.43 532.41 495.3ZM532.41 494.1C538.23 494.17 543.53 489.17 543.69 483.34C543.73 478.2 543.57 470.48 543.53 465.23L543.22 447.08C543.18 444.07 542.98 436.38 542.9 433.46C542.84 431.97 542.87 430.34 542.68 429.14C541.87 423.85 536.86 420.03 531.55 420.61C490.39 421.98 445.56 423.7 404.48 424.28C284.61 425.68 161.28 424.47 41.4098 421.75C36.8898 421.54 32.6098 425.37 32.3298 429.95C32.2698 430.44 32.2698 431.34 32.2498 432.05L32.1998 434.32C32.1198 437.18 32.0098 444.97 31.9498 447.94C31.8298 453.93 31.5898 469.37 31.5398 475.17C31.5098 477.53 31.3998 481.81 31.5398 483.98C31.9298 487.74 34.7298 491.2 38.3698 492.32C39.7198 492.74 40.7898 492.82 42.2698 492.81C133.04 493.86 223.8 488.45 314.57 489.38C386.76 489.85 460.21 494.46 532.41 494.1Z"
        fill="black"
      />
      <path
        d="M532.409 525.2H41.3994C34.772 525.2 29.3994 530.573 29.3994 537.2V589.44C29.3994 596.068 34.772 601.44 41.3994 601.44H532.409C539.037 601.44 544.409 596.068 544.409 589.44V537.2C544.409 530.573 539.037 525.2 532.409 525.2Z"
        fill="#EBEBEE"
      />
      <path
        d="M532.41 602.03C484.97 601.87 434.75 603.65 387.18 605.25C278.25 609.76 169.35 603.18 60.4198 603.25L42.2698 603.32C38.9398 603.47 35.2098 602.42 32.5998 600.21C29.8598 598.02 27.9698 594.75 27.4998 591.23C27.1598 588.21 27.3998 584.82 27.2698 581.9C27.1998 576.16 26.9898 560.61 26.8598 554.67C26.7998 549.34 26.5598 541.65 26.5498 536.35C26.7398 528.59 33.5898 521.92 41.3998 521.91C161.28 519.2 284.59 517.98 404.47 519.38C445.53 519.95 490.43 521.7 531.54 523.06C538.56 522.77 545.34 528.33 545.92 535.45C546.18 538.39 545.77 541.87 545.8 544.73C545.7 550.47 545.36 566.01 545.29 571.96C545.18 578.03 545.2 584.02 545.06 590.15C544.75 596.65 538.93 602.16 532.41 602.03ZM532.41 600.83C538.23 600.9 543.53 595.9 543.69 590.07C543.73 584.93 543.57 577.21 543.53 571.96L543.22 553.81C543.18 550.8 542.98 543.11 542.9 540.19C542.84 538.7 542.87 537.07 542.68 535.87C541.87 530.58 536.86 526.76 531.55 527.34C490.39 528.71 445.56 530.43 404.48 531.01C284.61 532.41 161.28 531.2 41.4098 528.48C36.8898 528.27 32.6098 532.1 32.3298 536.68C32.2698 537.17 32.2698 538.07 32.2498 538.78L32.1998 541.05C32.1198 543.91 32.0098 551.7 31.9498 554.67C31.8298 560.66 31.5898 576.1 31.5398 581.9C31.5098 584.26 31.3998 588.54 31.5398 590.71C31.9298 594.47 34.7298 597.93 38.3698 599.05C39.7198 599.47 40.7898 599.55 42.2698 599.54C133.04 600.59 223.8 595.18 314.57 596.11C386.76 596.58 460.21 601.19 532.41 600.83Z"
        fill="black"
      />
      <path
        d="M69.8998 473.67C79.0457 473.67 86.4598 466.256 86.4598 457.11C86.4598 447.964 79.0457 440.55 69.8998 440.55C60.754 440.55 53.3398 447.964 53.3398 457.11C53.3398 466.256 60.754 473.67 69.8998 473.67Z"
        fill="#6E7EC3"
      />
      <path
        d="M86.7202 457.11C86.8502 474.36 69.7302 482.34 57.6302 469.39C51.0702 462.61 49.5202 450.9 56.5302 443.73C59.8702 440.08 64.8602 437.78 69.9102 437.88C80.5802 437.94 86.9002 447.15 86.7202 457.11ZM86.2202 457.11C86.0202 444.63 68.5102 438.9 59.8702 447.07C51.9402 454.18 52.9102 468.04 64.1002 471.14C73.2902 473.42 86.1502 467.74 86.2202 457.11Z"
        fill="black"
      />
      <path
        d="M116.59 473.67C125.736 473.67 133.15 466.256 133.15 457.11C133.15 447.964 125.736 440.55 116.59 440.55C107.444 440.55 100.03 447.964 100.03 457.11C100.03 466.256 107.444 473.67 116.59 473.67Z"
        fill="#6E7EC3"
      />
      <path
        d="M133.4 457.11C133.53 474.36 116.41 482.34 104.31 469.39C97.7499 462.61 96.1999 450.9 103.21 443.73C106.55 440.08 111.54 437.78 116.59 437.88C127.26 437.94 133.58 447.15 133.4 457.11ZM132.9 457.11C132.7 444.63 115.19 438.9 106.55 447.07C98.6199 454.18 99.5899 468.04 110.78 471.14C119.97 473.42 132.83 467.74 132.9 457.11Z"
        fill="black"
      />
      <path
        d="M69.8998 582.09C79.0457 582.09 86.4598 574.676 86.4598 565.53C86.4598 556.384 79.0457 548.97 69.8998 548.97C60.754 548.97 53.3398 556.384 53.3398 565.53C53.3398 574.676 60.754 582.09 69.8998 582.09Z"
        fill="#6E7EC3"
      />
      <path
        d="M86.7202 565.53C86.8502 582.78 69.7302 590.76 57.6302 577.81C51.0702 571.03 49.5202 559.32 56.5302 552.15C59.8702 548.5 64.8602 546.2 69.9102 546.3C80.5802 546.36 86.9002 555.57 86.7202 565.53ZM86.2202 565.53C86.0202 553.05 68.5102 547.32 59.8702 555.49C51.9402 562.6 52.9102 576.46 64.1002 579.56C73.2902 581.84 86.1502 576.16 86.2202 565.53Z"
        fill="black"
      />
      <path
        d="M116.59 582.09C125.736 582.09 133.15 574.676 133.15 565.53C133.15 556.384 125.736 548.97 116.59 548.97C107.444 548.97 100.03 556.384 100.03 565.53C100.03 574.676 107.444 582.09 116.59 582.09Z"
        fill="#6E7EC3"
      />
      <path
        d="M133.4 565.53C133.53 582.78 116.41 590.76 104.31 577.81C97.7499 571.03 96.1999 559.32 103.21 552.15C106.55 548.5 111.54 546.2 116.59 546.3C127.26 546.36 133.58 555.57 133.4 565.53ZM132.9 565.53C132.7 553.05 115.19 547.32 106.55 555.49C98.6199 562.6 99.5899 576.46 110.78 579.56C119.97 581.84 132.83 576.16 132.9 565.53Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_2702_144">
        <rect width={591.31} height={606.83} fill="white" />
      </clipPath>
    </defs>
  </svg>
))`
  width: 110px;
  height: 113px;
  flex-shrink: 0;
`;

const UploadingAnimation = () => {
  return (
    <Container>
      <UploadingIcon />
    </Container>
  );
};

export default UploadingAnimation;

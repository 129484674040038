import styled from 'styled-components';
import React from 'react';

const VenomIcon = styled((props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="112" height="113" viewBox="0 0 112 113" fill="none">
    <g clipPath="url(#clip0_2099_21693)">
      <path
        d="M56 0.0107422C86.929 0.0107422 112 25.0852 112 56.0107C112 86.9363 86.929 112.011 56 112.011C25.071 112.011 0 86.9397 0 56.0107C0 25.0818 25.0744 0.0107422 56 0.0107422Z"
        fill="#222625"
      />
      <path
        d="M76.6323 28.13C74.9724 27.8677 73.246 28.0316 71.6525 28.5562C70.0589 29.0808 68.6314 29.9661 67.5026 31.1136C65.3447 33.3104 64.6807 36.1957 65.2119 39.081C65.6767 41.6712 66.3075 44.327 66.4403 46.8189C66.6395 50.5895 66.0087 54.36 64.6143 57.9339C63.3528 61.1471 61.5268 63.6389 57.6094 64.1963C57.3106 64.2291 56.2814 64.2619 55.9826 64.2947C55.6838 64.2947 54.7543 64.2619 54.4555 64.1963C50.5712 63.6389 48.7121 61.1471 47.4505 57.9339C46.023 54.3928 45.4254 50.6222 45.6246 46.8189C45.7574 44.327 46.3881 41.6712 46.8529 39.081C47.3841 36.1957 46.6869 33.3104 44.5622 31.1136C43.4334 29.9661 42.0059 29.0808 40.4123 28.5562C38.7524 27.9988 37.0261 27.8677 35.3661 28.13C30.3531 28.8841 27 32.9497 27 37.2121C27 38.8515 27.4648 40.4909 28.3944 41.868C29.9547 44.2287 32.511 45.9336 34.7353 47.7369C36.1961 48.9173 38.7524 52.3272 40.014 55.9666C40.7775 58.1962 41.3751 60.4257 42.0059 62.7209C42.8691 65.9013 44.031 72.885 44.8278 76.0654C46.3881 82.4262 51.1356 83.8688 55.883 84H56.049C60.7965 83.8688 65.6103 82.4262 67.1706 76.0654C67.9342 72.885 69.1294 65.9013 69.9925 62.7209C70.5901 60.4585 71.2209 58.1962 71.9845 55.9666C73.246 52.2944 75.7692 48.9173 77.2631 47.7369C79.4874 45.9336 82.0437 44.2287 83.6041 41.868C84.5337 40.4581 85.0316 38.8515 84.9984 37.2121C84.9984 32.9169 81.6454 28.8513 76.6323 28.13Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2099_21693">
        <rect width="112" height="112" fill="white" transform="translate(0 0.0107422)" />
      </clipPath>
    </defs>
  </svg>
))`
  margin-right: 30px;
  flex-shrink: 0;
`;

export default VenomIcon;

import styled from 'styled-components';
import { shakeAnimation } from './shakeAnimation';

const Silver = styled(({ shaking, ...props }) => (
  <svg {...props} width="2969" height="2996" viewBox="0 0 2969 2996" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1484.5 2996.04C2304.37 2996.04 2969 2869.12 2969 2712.56C2969 2555.99 2304.37 2429.07 1484.5 2429.07C664.633 2429.07 0 2555.99 0 2712.56C0 2869.12 664.633 2996.04 1484.5 2996.04Z"
      fill="#FFD9AC"
    />
    <path
      d="M2748.54 2666.04H2054.89L2076.57 2644.37L2380.04 2600.97V2102.41L2324.54 962.005H2349.51L2369.22 1266.14L2369.45 1268.95L2423.39 2102.41L2445.07 2600.97L2726.86 2644.37L2748.54 2666.04Z"
      fill="url(#paint0_linear_2458_259)"
    />
    <path
      d="M2748.76 2666.09L2054.89 2667.69H2050.84L2053.69 2664.84L2075.32 2643.12L2075.72 2642.72L2076.3 2642.63C2151.97 2631.46 2304.06 2610.01 2379.77 2599.14L2378.17 2601.01C2378.26 2543.77 2378.75 2161.52 2378.84 2114.07C2370.25 1885.64 2334.11 1200.18 2322.76 962.138L2322.67 960.269H2324.58L2349.51 960.224H2351.24C2351.33 959.735 2404.7 1780.91 2404.79 1781.13C2409.24 1849.59 2420.5 2022.29 2424.55 2088.34C2428.2 2157.69 2442.8 2524.63 2446.23 2600.97L2445.25 2599.9C2445.43 2599.99 2727 2643.97 2727.09 2644.14L2748.76 2666.13V2666.09ZM2748.36 2665.91L2726.64 2644.55C2727.58 2644.86 2444.71 2601.99 2444.89 2602.08L2443.96 2601.95V2601.01C2442.35 2567.1 2424.11 2162.67 2423.04 2139.75C2418.63 2046.46 2405.94 1876.07 2400.38 1781.35C2402.07 1784.51 2343.9 952.702 2349.42 963.874H2324.49L2326.27 962.005C2337.22 1204.94 2370.56 1882.26 2381.15 2114.07C2381.24 2159.6 2381.78 2546.36 2381.82 2601.06V2602.66L2380.22 2602.88C2304.64 2613.57 2152.33 2635.6 2076.7 2646.1L2077.68 2645.61L2055.96 2667.25C2045.46 2660.26 2750.01 2667.82 2748.23 2665.95L2748.36 2665.91Z"
      fill="black"
    />
    <path
      d="M2542.06 660.135L2458.11 883.8L2329.43 1227.06C2325.43 1238.64 2309.85 1239.13 2304.29 1228.58L2019.82 660.135C2018.31 656.618 2018.31 653.102 2019.33 649.586L2197.77 113.813L2231.96 11.7952C2235.96 -0.267107 2252.57 -1.29085 2257.6 10.2819L2542.06 650.075C2543.08 653.102 2543.57 657.108 2542.06 660.135Z"
      fill="#FFB53D"
    />
    <path
      d="M2542.24 660.224C2485.49 810.491 2389.61 1073.73 2333.53 1221.5L2331.66 1226.4C2331.08 1227.95 2330.32 1229.96 2329.17 1231.56C2324.63 1238.33 2314.57 1239.88 2307.98 1235.25C2302.51 1232.05 2299.52 1222.04 2296.5 1216.92L2287.15 1198.09C2216.02 1053.74 2095.22 812.45 2024.41 671.618L2019.69 662.227C2013.99 652.301 2021.91 641.084 2024.23 631.336C2066.29 505.06 2154.2 236.84 2195.95 112.656L2222.61 32.9377L2229.29 12.997C2229.82 11.4391 2230.4 9.48065 2231.51 7.74474C2234.54 2.448 2240.9 -0.623228 2246.91 0.0889399C2250.92 0.534045 2254.88 2.49251 2257.33 5.74177C2259.86 9.16908 2260.75 12.4183 2262.31 15.5786L2270.86 34.7626L2339.09 188.368C2381.78 285.579 2497.95 549.304 2542.28 649.942C2543.3 653.191 2543.79 656.975 2542.28 660.179L2542.24 660.224ZM2541.84 660.046C2543.3 657.019 2542.77 653.236 2541.84 650.164C2496.3 548.681 2379.42 288.205 2335.26 190.104C2330.77 180.267 2261.33 23.724 2258.4 17.3145C2256.93 14.1988 2255.68 10.5934 2253.99 8.4124C2247.67 0.845618 2235.25 4.36195 2233.38 14.3323C2212.82 75.4452 2167.68 210.579 2146.81 273.383C2138.22 299.511 2032.24 614.289 2026.45 631.915C2024.27 641.574 2016.48 651.633 2021.69 661.247L2026.36 670.639L2063.84 745.862C2119.03 858.696 2242.46 1100.21 2299.7 1215.22L2304.42 1224.62C2306.02 1227.91 2307.36 1230.54 2310.07 1232.18C2315.15 1235.74 2322.89 1234.45 2326.23 1229.33C2327.83 1226.93 2328.77 1223.33 2330.06 1220.12L2333.71 1210.28C2386.81 1068.16 2487.27 804.571 2541.88 659.957L2541.84 660.046Z"
      fill="black"
    />
    <path
      d="M2302.37 1114.28C2293.38 1114.28 2285.06 1109.2 2281 1101.01L2055.96 650.921C2053.11 645.402 2052.67 638.725 2054.63 632.672L2223.01 127.611C2226.17 118.175 2234.58 111.766 2244.55 111.321C2255.32 109.852 2263.47 116.395 2267.56 125.475L2492.56 631.693C2495.14 637.523 2495.32 643.933 2493.14 649.808L2324.8 1098.7C2321.42 1107.73 2313.32 1113.7 2303.7 1114.19H2302.41L2302.37 1114.28ZM2245.98 141.009L2079.33 640.951L2302.15 1085.12L2468.75 640.728L2245.98 141.009Z"
      fill="#1C424E"
    />
    <path
      d="M2302.37 1114.5C2289.95 1114.68 2281.72 1105.42 2277.49 1094.65L2262.31 1064.47L2231.91 1004.11C2181.35 903.652 2114.14 772.568 2064.6 672.375L2057.08 657.242C2054.49 652.212 2051.47 646.559 2051.64 640.595C2051.07 635.387 2054.4 628.621 2055.78 623.725C2077.77 558.339 2114.27 449.956 2136.61 383.636C2165.68 297.998 2193.81 212.048 2222.25 126.187L2223.32 126.543L2235.65 130.638L2234.94 132.819L2222.61 128.724L2221.5 128.368C2225.68 111.721 2248.38 103.754 2261.51 115.06C2266.23 118.887 2268.76 124.674 2271.04 130.015L2285.23 161.662C2340.25 283.265 2434.92 497.449 2490.16 621.055C2491.98 625.817 2495.94 632.405 2496.52 637.791C2497.64 643.888 2495.45 650.298 2493.23 655.684L2487.13 671.93C2470.8 715.951 2430.43 821.263 2413.29 866.53C2397.27 908.503 2354.94 1020.27 2339.4 1061.13L2327.07 1093.58C2324.14 1104.88 2314.79 1115.03 2302.37 1114.45V1114.5ZM2302.37 1114.05C2314.61 1114.28 2323.6 1104.66 2326.41 1093.36L2338.47 1060.82C2353.47 1020.31 2395.17 907.747 2410.71 865.595C2427.8 819.482 2466.17 714.794 2482.99 670.417L2489.09 654.17C2491.18 648.874 2493.1 644.022 2492.16 638.458C2491.67 634.096 2487.93 627.108 2486.15 622.88C2430.65 498.651 2336.64 285.713 2282.47 162.953L2268.5 131.217C2266.23 125.965 2264.18 120.801 2259.77 117.152C2248.42 107.27 2228.31 113.813 2224.12 128.057L2223.36 126.543L2235.69 130.683L2234.98 132.864L2222.65 128.724C2199.46 208.354 2164.7 300.268 2138.44 384.303C2116.72 451.336 2080.53 558.874 2059.03 624.838C2057.83 629.067 2054.72 636.01 2055.16 640.595C2055.12 646.159 2057.79 650.743 2060.28 655.639L2067.89 670.728C2129.54 793.132 2201.78 941.263 2262.93 1064.11L2277.98 1094.33C2282.16 1104.88 2290.04 1114.28 2302.37 1114.01V1114.05Z"
      fill="black"
    />
    <path
      d="M2245.98 141.232C2199.46 283.443 2128.02 501.411 2080.89 641.485C2117.83 712.39 2209.52 900.848 2247.22 973.711L2303.22 1084.59L2300.99 1084.72C2341.63 976.56 2426.64 743.191 2466.75 641.619C2404.48 502.167 2308.16 282.597 2245.98 141.232ZM2245.98 140.787C2309.62 282.953 2408.57 499.096 2470.71 639.883C2430.96 751.337 2344.97 974.29 2303.3 1085.57L2302.33 1088.19L2301.08 1085.7C2238.54 958.622 2141.33 768.562 2077.82 640.461C2123.62 500.12 2198.48 282.241 2246.02 140.831L2245.98 140.787Z"
      fill="black"
    />
    <path
      d="M2343.01 564.348C2343.01 564.348 2369.36 350.742 2261.51 347.315C2153.66 343.932 2209.08 781.648 2209.08 781.648L2155.27 783.473L2225.68 873.251L2290 785.966L2242.95 787.568C2242.95 787.568 2209.43 398.725 2262.98 389.778C2316.53 380.832 2309.63 572.538 2309.63 572.538L2343.01 564.304V564.348Z"
      fill="#1C424E"
    />
    <path
      d="M2342.88 564.215C2347.55 504.793 2350 390.98 2291.82 357.107C2280.03 350.609 2265.65 347.582 2252.39 349.941C2237.03 353.769 2227.28 367.745 2220.87 381.455C2203.16 422.582 2200.04 468.384 2197.42 512.716C2196.08 542.583 2196.08 572.538 2196.84 602.449C2198.58 662.271 2202.58 722.138 2209.97 781.559L2210.06 782.45H2209.17L2155.4 784.497L2156.16 782.895L2226.88 872.405H2224.61L2288.53 784.853L2290.13 787.924L2243.09 789.66L2241.13 789.749C2233.12 687.998 2226.88 585.802 2232.63 483.74C2234.72 458.369 2236.76 432.731 2245.27 408.428C2250.21 394.897 2259.2 382.79 2275.36 389.778C2296.94 401.84 2301.26 436.47 2305.31 458.68C2310.7 496.381 2311.45 534.615 2310.25 572.627L2309.58 572.093L2342.92 564.215H2342.88ZM2343.19 564.526L2309.76 573.117L2309.09 573.295C2309.58 535.149 2308.34 496.781 2302.64 459.036C2298.55 437.894 2294.05 404.155 2273.84 392.805C2247.98 381.9 2243.53 429.66 2240.5 446.351C2228.49 559.541 2237.48 674.111 2244.95 787.435L2242.82 785.565L2289.91 784.096L2293.92 783.963L2291.56 787.168L2226.84 874.141L2225.73 875.654L2224.57 874.186L2154.51 784.141L2153.31 782.583H2155.27L2209.08 780.847L2208.28 781.782C2198.53 686.752 2170.85 432.775 2229.64 360.535C2235.25 353.903 2242.91 348.74 2251.54 346.603C2258.22 345.09 2265.07 345.891 2271.75 346.692C2353.96 362.671 2350.27 499.942 2343.19 564.526Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
      opacity={0.56}
    >
      <path d="M2423.39 2600.97L2488.42 2644.37L2716.8 2642.81L2423.39 2600.97Z" fill="url(#paint1_linear_2458_259)" />
      <path
        d="M2423.62 2601.06C2444.09 2614.9 2471.73 2630.48 2488.42 2642.59C2564.53 2640.98 2640.69 2643.83 2716.8 2641.47L2716.63 2644.06C2631.88 2633.86 2509.21 2614.19 2423.62 2601.06ZM2423.21 2600.88C2510.23 2612.32 2631.66 2627.41 2717.03 2641.47L2716.85 2644.06C2640.69 2642.72 2564.62 2646.59 2488.47 2646.01C2466.26 2632.17 2444.85 2615.21 2423.21 2600.84V2600.88Z"
        fill="black"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
      opacity={0.56}
    >
      <path
        d="M2423.4 2600.97V2167.44L2369.45 1268.95L2423.4 2102.41L2445.07 2600.97H2423.4Z"
        fill="url(#paint2_linear_2458_259)"
      />
      <path
        d="M2423.26 2601.15C2421.84 2508.12 2423.93 2230.73 2420.1 2142.65C2406.97 1933.76 2391.75 1644.71 2378.57 1435.42L2368.25 1269.04L2370.65 1268.9C2387.03 1509.31 2410.09 1861.61 2425.53 2101.03C2429.94 2207.54 2441.15 2491.07 2445.47 2601.01V2601.37H2445.12L2423.31 2601.19L2423.26 2601.15ZM2423.57 2600.84L2445.07 2600.66C2444.09 2602.71 2429.27 2267.5 2428.91 2267.85C2415.25 1962.78 2385.78 1575.18 2368.25 1269.04L2370.65 1268.9L2380.31 1435.33C2392.33 1644.84 2411.78 1933.49 2423.75 2142.56C2427.36 2229.35 2422.9 2510.03 2423.57 2600.84Z"
        fill="black"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.44}
    >
      <path
        d="M2458.11 883.8L2329.43 1227.06C2325.43 1238.64 2309.85 1239.13 2304.29 1228.58L2019.82 660.135C2018.31 656.618 2018.31 653.102 2019.33 649.586L2197.77 113.813C2196.26 267.107 2214.87 547.568 2354.09 756.144C2386.76 804.883 2421.93 847.123 2458.11 883.8Z"
        fill="#935E9E"
      />
    </g>
    <path d="M2051.12 2261.49L2050.14 2258.51L2153.22 2106.77L2154.2 2109.75L2051.12 2261.49Z" fill="#17130A" />
    <path d="M2067 2296.7L2065.85 2294.25L2100.25 1965.05L2101.41 1967.5L2067 2296.7Z" fill="#17130A" />
    <path d="M2228.4 2388.65L2055.52 2264.2L2054.99 2261.22L2227.87 2385.67L2228.4 2388.65Z" fill="#17130A" />
    <path d="M2194.22 2232.91L2067.67 2307.91L2066.83 2304.84L2193.33 2229.84L2194.22 2232.91Z" fill="#17130A" />
    <path d="M2257.02 2640.72L2060.64 2273.42L2060.69 2271.15L2257.06 2638.45L2257.02 2640.72Z" fill="#17130A" />
    <path d="M2250.52 2513.15L2065.81 2313.88L2065.45 2310.98L2250.17 2510.3L2250.52 2513.15Z" fill="#17130A" />
    <path d="M2232.36 2798.33L2065.09 2286.59L2065.72 2285.7L2232.94 2797.48L2232.36 2798.33Z" fill="#17130A" />
    <path d="M2255.33 2733.17L2061.93 2312.99L2062.11 2311.07L2255.55 2731.25L2255.33 2733.17Z" fill="#17130A" />
    <path d="M2161.99 2820.05L2161.01 2819.29L2067.68 2300.26L2068.7 2300.97L2161.99 2820.05Z" fill="#17130A" />
    <path d="M2208.06 2833.54L2207.3 2833.94L2056.99 2305.46L2057.75 2305.06L2208.06 2833.54Z" fill="#17130A" />
    <path d="M2063.22 2700.14L2062.07 2697.96L2067.72 2310.67L2068.88 2312.81L2063.22 2700.14Z" fill="#17130A" />
    <path d="M2120.37 2789.69L2119.31 2788.54L2052.41 2293.36L2053.47 2294.51L2120.37 2789.69Z" fill="#17130A" />
    <path d="M1963.12 2469.84L1962.14 2466.9L2065.23 2315.12L2066.21 2318.11L1963.12 2469.84Z" fill="#17130A" />
    <path d="M2016.04 2611.56L2014.93 2609.11L2049.34 2279.92L2050.45 2282.36L2016.04 2611.56Z" fill="#17130A" />
    <path d="M2061.35 2315.43L1888.43 2190.94L1887.9 2187.96L2060.82 2312.41L2061.35 2315.43Z" fill="#17130A" />
    <path d="M2049.51 2271.77L1923.02 2346.77L1922.12 2343.7L2048.62 2268.7L2049.51 2271.77Z" fill="#17130A" />
    <path d="M2055.66 2305.46L1859.28 1938.16L1859.32 1935.89L2055.7 2303.19L2055.66 2305.46Z" fill="#17130A" />
    <path d="M2050.89 2265.63L1866.18 2066.31L1865.78 2063.46L2050.49 2262.73L2050.89 2265.63Z" fill="#17130A" />
    <path d="M2050.62 2290.91L1883.36 1779.17L1883.98 1778.28L2051.2 2290.02L2050.62 2290.91Z" fill="#17130A" />
    <path d="M2054.19 2265.54L1860.79 1845.36L1860.97 1843.45L2054.41 2263.62L2054.19 2265.54Z" fill="#17130A" />
    <path d="M2048.62 2276.35L2047.6 2275.64L1954.3 1756.61L1955.33 1757.32L2048.62 2276.35Z" fill="#17130A" />
    <path d="M2059.3 2271.15L2058.59 2271.59L1908.28 1743.07L1909.04 1742.67L2059.3 2271.15Z" fill="#17130A" />
    <path d="M2048.58 2265.94L2047.42 2263.8L2053.07 1876.52L2054.23 1878.65L2048.58 2265.94Z" fill="#17130A" />
    <path d="M2063.93 2283.25L2062.86 2282.1L1995.92 1786.92L1997.03 1788.07L2063.93 2283.25Z" fill="#17130A" />
    <path d="M1381.9 1940.83L1381.06 1938.16L1479.92 1800.58L1480.76 1803.21L1381.9 1940.83Z" fill="#17130A" />
    <path d="M1395.88 1971.9L1394.9 1969.77L1433.85 1675.29L1434.87 1677.47L1395.88 1971.9Z" fill="#17130A" />
    <path d="M1543.61 2050.51L1385.96 1943.15L1385.51 1940.48L1543.17 2047.79L1543.61 2050.51Z" fill="#17130A" />
    <path d="M1515.22 1912.26L1396.28 1981.92L1395.53 1979.16L1514.5 1909.54L1515.22 1912.26Z" fill="#17130A" />
    <path d="M1564.75 2274.75L1390.5 1951.25L1390.59 1949.25L1564.84 2272.75L1564.75 2274.75Z" fill="#17130A" />
    <path d="M1561.46 2161.12L1394.41 1987.26L1394.14 1984.68L1561.19 2158.54L1561.46 2161.12Z" fill="#17130A" />
    <path d="M1538.49 2415.94L1394.32 1962.91L1394.9 1962.11L1539.02 2415.14L1538.49 2415.94Z" fill="#17130A" />
    <path d="M1561.19 2357.27L1390.8 1986.55L1391.07 1984.81L1561.41 2355.58L1561.19 2357.27Z" fill="#17130A" />
    <path d="M1472.75 2436.77L1471.86 2436.15L1396.46 1975.06L1397.4 1975.69L1472.75 2436.77Z" fill="#17130A" />
    <path d="M1515.17 2447.85L1514.5 2448.25L1386.45 1979.96L1387.11 1979.56L1515.17 2447.85Z" fill="#17130A" />
    <path d="M1383.73 2331.9L1382.71 2330.03L1396.28 1984.37L1397.31 1986.24L1383.73 2331.9Z" fill="#17130A" />
    <path d="M1434.83 2410.64L1433.85 2409.62L1382.39 1969.23L1383.37 1970.26L1434.83 2410.64Z" fill="#17130A" />
    <path d="M1295.82 2128.62L1294.98 2126L1393.83 1988.37L1394.68 1991.04L1295.82 2128.62Z" fill="#17130A" />
    <path d="M1341.89 2253.92L1340.87 2251.74L1379.86 1957.3L1380.84 1959.44L1341.89 2253.92Z" fill="#17130A" />
    <path d="M1390.23 1988.73L1232.57 1881.37L1232.13 1878.7L1389.83 1986.06L1390.23 1988.73Z" fill="#17130A" />
    <path d="M1380.21 1950.05L1261.28 2019.66L1260.53 2016.95L1379.46 1947.29L1380.21 1950.05Z" fill="#17130A" />
    <path d="M1385.15 1979.96L1210.94 1656.46L1210.99 1654.41L1385.24 1977.96L1385.15 1979.96Z" fill="#17130A" />
    <path d="M1381.59 1944.53L1214.59 1770.67L1214.28 1768.09L1381.33 1941.95L1381.59 1944.53Z" fill="#17130A" />
    <path d="M1380.83 1967.1L1236.71 1514.07L1237.29 1513.27L1381.41 1966.29L1380.83 1967.1Z" fill="#17130A" />
    <path d="M1384.71 1944.4L1214.32 1573.62L1214.55 1571.93L1384.93 1942.66L1384.71 1944.4Z" fill="#17130A" />
    <path d="M1379.28 1954.14L1378.39 1953.52L1302.99 1492.44L1303.88 1493.06L1379.28 1954.14Z" fill="#17130A" />
    <path d="M1389.34 1949.25L1388.63 1949.65L1260.57 1481.35L1261.28 1480.95L1389.34 1949.25Z" fill="#17130A" />
    <path d="M1379.46 1944.84L1378.43 1942.97L1392.01 1597.3L1393.03 1599.17L1379.46 1944.84Z" fill="#17130A" />
    <path d="M1393.34 1959.97L1392.36 1958.95L1340.91 1518.56L1341.89 1519.59L1393.34 1959.97Z" fill="#17130A" />
    <path
      d="M1651.77 2183.59C1654.18 2183.15 1656.58 2182.7 1658.98 2182.3C1716.45 2010.58 1773.91 1838.91 1831.33 1667.18L1822.78 1658.95C1765.76 1833.83 1708.75 2008.76 1651.77 2183.64V2183.59Z"
      fill="#2D2C2A"
    />
    <path
      d="M1651.91 2183.46L1658.94 2181.99L1658.76 2182.17C1678.61 2119.99 1701.71 2051 1722.19 1988.59C1755.61 1889.11 1798.08 1766.49 1830.48 1666.83L1830.7 1667.81L1822.11 1659.62L1823.71 1659.22C1809.65 1702.93 1795.9 1746.64 1781.61 1790.21C1741.99 1912.44 1692.54 2061.46 1651.91 2183.51V2183.46ZM1651.64 2183.68C1706.79 2013.79 1764.25 1825.24 1823.09 1657.93C1823.22 1657.97 1823.36 1658.06 1823.49 1658.15L1831.99 1666.43C1832.26 1666.69 1832.35 1667.05 1832.22 1667.41L1810.41 1731.72C1776.71 1831.12 1737.36 1954.77 1703.18 2053.85C1688.49 2096.71 1674.29 2139.93 1658.98 2182.48L1651.59 2183.64L1651.64 2183.68Z"
      fill="black"
    />
    <path d="M1762.29 1459.59L1556.52 1383.61L1549.09 1394.51L1764.92 1471.43L1762.29 1459.59Z" fill="#2D2C2A" />
    <path
      d="M1762.16 1459.72C1693.92 1436.66 1621.37 1408.93 1557.1 1383.96L1549.84 1395L1549.4 1393.67C1620.88 1417.97 1694.81 1443.92 1765.09 1470.94L1764.47 1471.47L1762.2 1459.68L1762.16 1459.72ZM1762.42 1459.5L1765.36 1471.29C1765.45 1471.65 1765.09 1472.01 1764.74 1471.83C1693.26 1448.37 1619.46 1421.66 1548.78 1395.31C1548.24 1395.14 1547.97 1394.42 1548.33 1393.98L1555.9 1383.16C1624.4 1407.29 1694.59 1432.21 1762.42 1459.45V1459.5Z"
      fill="black"
    />
    <path
      d="M1651.64 2181.59C1586.07 1916.75 1520.51 1651.96 1454.95 1387.12C1472.13 1386.55 1489.35 1386.01 1506.53 1385.43L1672.07 2122.08L1651.59 2181.59H1651.64Z"
      fill="#4B4B4B"
    />
    <path
      d="M1651.64 2181.81C1587.5 1924.99 1514.54 1641.64 1454.95 1385.92L1506.53 1384.01C1507.2 1384.01 1507.78 1384.45 1507.96 1385.08C1546.86 1554.44 1595.69 1768.4 1632.59 1937.45C1632.45 1937.63 1673.27 2122.08 1672.91 2122.3L1651.64 2181.81ZM1651.64 2181.37L1671.31 2121.81C1671.44 2124 1628.89 1937.9 1628.85 1938.34C1589.94 1769.96 1542.41 1555.24 1505.15 1385.74L1506.58 1386.81L1454.99 1388.28C1520.06 1636.16 1589.05 1926.28 1651.64 2181.32V2181.37Z"
      fill="black"
    />
    <path
      d="M2107.47 2289.31C2047.96 2113.94 2013.55 2003.06 1997.35 1938.03C1992.27 1917.6 1977.9 1857.24 1943.58 1783C1935.43 1765.42 1928.13 1751.22 1923.02 1741.65C1912.96 1724.91 1902.81 1711.78 1894.8 1702.39C1897.11 1680.09 1899.43 1657.84 1901.74 1635.54C1914.43 1641.72 1932.19 1652.45 1947.27 1670.97C1966.73 1694.87 1973.58 1725.98 1974.51 1730.39C1982.93 1769.69 2035.89 1988.24 2107.47 2289.31Z"
      fill="#4B4B4B"
    />
    <path
      d="M2107.51 2289.53C2067.94 2178.34 2029.97 2067.33 1999.35 1953.43C1987.55 1903.18 1972.69 1851.59 1952.12 1804.32C1944.96 1786.83 1935.43 1768.27 1926.98 1751.26C1918.61 1733.68 1906.5 1717.84 1894.04 1702.97C1893.86 1702.75 1893.77 1702.48 1893.82 1702.21L1900.49 1635.36C1900.58 1634.51 1901.52 1633.93 1902.32 1634.34C1928.89 1646.84 1951.77 1668.03 1964.23 1694.87C1975.89 1717.66 1981.77 1754.42 1988.4 1779.26C2026.46 1936.16 2070.21 2131.47 2107.55 2289.44L2107.51 2289.53ZM2107.38 2289.08C2069.54 2130.98 2020.76 1937.18 1983.99 1779.84C1969.26 1717.61 1965.08 1668.79 1901.12 1636.65L1902.94 1635.63L1895.73 1702.44C1902.45 1708.89 1912.15 1723.31 1918.79 1733.33C1921.95 1737.87 1925.86 1745.43 1928.22 1750.55C1943.04 1779.53 1957.02 1809.53 1968.41 1840.02C1987.82 1888.67 1998.99 1940.03 2013.5 1990.2C2038.7 2077.79 2079.33 2201.13 2107.38 2289.08Z"
      fill="black"
    />
    <path
      d="M2202.94 2248.31C2121.22 1933.4 1987.86 1695.58 1905.07 1717.08C1822.29 1738.58 1821.44 2011.25 1903.16 2326.16C1984.88 2641.07 2118.23 2878.89 2201.02 2857.39C2283.81 2835.9 2284.66 2563.22 2202.94 2248.31ZM2193.59 2828.69C2192.56 2828.95 2191.5 2829.09 2190.34 2829.09C2185.75 2829.09 2143.34 2825.21 2071.01 2691.46C2018.44 2594.25 1969.04 2461.87 1931.87 2318.68C1892.97 2168.77 1871.74 2024.25 1872.09 1911.68C1872.49 1788.65 1898.71 1749.35 1912.55 1745.79C1913.58 1745.52 1914.64 1745.39 1915.8 1745.39C1920.39 1745.39 1962.8 1749.26 2035.13 1883.02C2087.7 1980.23 2137.11 2112.6 2174.27 2255.79C2213.17 2405.7 2234.4 2550.23 2234.05 2662.79C2233.65 2785.82 2207.43 2825.12 2193.59 2828.69Z"
      fill="#17130A"
    />
    <path
      d="M2202.71 2248.4C2156.82 2084.11 2102.75 1915.95 2000.1 1777.61C1953.5 1717.17 1890.03 1680.49 1859.81 1777.61C1852.06 1802.76 1848.28 1829.07 1846.01 1855.33C1838.22 1960.91 1850.91 2067.11 1869.73 2170.95C1899.07 2326.78 1943.98 2480.26 2008.03 2625.41C2029.7 2673.57 2053.69 2720.88 2082.89 2764.86C2124.55 2829.09 2207.74 2916.95 2245.62 2796.64C2287.02 2639.11 2241.62 2406.28 2202.76 2248.4H2202.71ZM2203.16 2248.27C2242.51 2402.68 2291.47 2644.01 2249.98 2797.97C2228.93 2868.03 2182.64 2881.07 2129.41 2829.22C2110.13 2810.66 2094.02 2789.29 2079.24 2767.26C2021.16 2678.28 1981.01 2579.07 1946.87 2478.88C1896.84 2328.12 1861.41 2172.16 1846.28 2013.92C1841.6 1961.18 1839.38 1908.07 1843.38 1855.11C1848.46 1774.14 1880.95 1656.72 1976.56 1745.39C2087.75 1859.29 2162.52 2094.35 2203.2 2248.22L2203.16 2248.27Z"
      fill="black"
    />
    <path
      d="M2193.63 2828.91C2157 2830.69 2109.73 2757.42 2091.75 2728.76C2015.68 2602.39 1967.39 2461.21 1930.13 2319.13C1905.96 2224.01 1887.09 2127.38 1877.48 2029.63C1872.89 1980.8 1870.04 1931.67 1871.83 1882.57C1873.52 1845.89 1876.63 1808.46 1889.67 1773.79C1892.08 1768.09 1894.57 1762.39 1898.09 1757.18C1902.18 1750.91 1908.55 1743.39 1917.18 1744.14C1983.1 1760.12 2055.74 1915.51 2082.22 1976.26C2174.09 2199.4 2238.94 2449.37 2233.91 2691.82C2232 2726.22 2226.35 2812.84 2193.54 2828.86L2193.63 2828.91ZM2193.5 2828.46C2199.29 2826.46 2203.34 2821.39 2206.72 2816.44C2216.64 2800.91 2221.18 2782.71 2224.97 2764.86C2238.23 2692.39 2233.34 2617.97 2226.3 2545.11C2215.8 2447.63 2196.26 2351.35 2172.05 2256.41C2134.35 2114.74 2086.9 1973.82 2012.3 1847.01C1994.41 1817.81 1948.61 1746.46 1912.82 1747.04C1870.36 1770.05 1871.83 1910.88 1873.69 1956.19C1882.69 2127.64 1922.26 2296.43 1975.49 2459.16C2006.65 2551.92 2042.65 2643.83 2092.77 2728.18C2110.53 2757.51 2157.49 2829.93 2193.5 2828.51V2828.46Z"
      fill="black"
    />
    <path
      d="M1792.11 1549.14C1742.84 1724.6 1693.52 1900.11 1644.25 2075.57C1646.74 2110.91 1649.19 2146.25 1651.68 2181.59C1708.74 2007.33 1765.76 1833.12 1822.82 1658.86C1812.58 1622.27 1802.35 1585.69 1792.11 1549.1V1549.14Z"
      fill="#4B4B4B"
    />
    <path
      d="M1792.11 1549.37C1750.05 1705.02 1688.76 1923.07 1644.96 2075.79C1644.92 2074.68 1652.93 2181.68 1652.88 2181.5L1650.43 2181.19C1687.65 2064.48 1739.32 1905.14 1777.82 1788.83C1777.56 1790.08 1822.15 1655.08 1821.44 1659.22L1792.07 1549.32L1792.11 1549.37ZM1792.11 1548.92L1824.11 1658.55C1824.69 1658.91 1782.05 1789.81 1782.14 1790.3C1744.44 1906.83 1691.87 2065.91 1652.84 2182.04C1652.48 2183.28 1650.43 2183.06 1650.39 2181.73L1643.4 2075.66C1655.78 2031.64 1668.02 1987.62 1680.03 1943.28C1703.85 1853.64 1765.85 1640.88 1792.07 1548.92H1792.11Z"
      fill="black"
    />
    <path
      d="M2029.26 2341.56C1943.98 2050.2 1883.13 1837.17 1874.85 1803.03C1869.29 1780.11 1867.42 1757.45 1867.42 1757.45C1865.82 1738 1866.4 1721.4 1867.42 1709.07C1852.55 1692.33 1837.64 1675.6 1822.78 1658.86C1816.1 1671.28 1807.95 1690.37 1806.49 1714.19C1804.57 1746.19 1815.66 1770.63 1819.04 1778.37C1838.84 1823.77 1918.74 2040.67 2029.21 2341.56H2029.26Z"
      fill="#4B4B4B"
    />
    <path
      d="M2029.17 2341.34C1986.44 2203.27 1939.88 2044.14 1901.65 1905C1894.88 1879.45 1877.79 1820.17 1872.32 1795.11C1866.44 1766.84 1864 1737.96 1866.4 1709.02C1868.22 1712.01 1821.53 1659.22 1821.8 1659.71L1823.85 1659.48C1800.7 1701.64 1804.44 1746.5 1825.45 1788.52C1853.71 1858.62 1879.8 1929.57 1906.1 2000.43C1947.18 2111.8 1989.69 2229.22 2029.13 2341.34H2029.17ZM2029.31 2341.78C1992.5 2246.35 1938.06 2099.29 1901.65 2002.08C1875.08 1931.35 1849.48 1860.32 1821.71 1790.08C1800.79 1747.08 1797.59 1700.92 1821.62 1658.28C1822.02 1657.53 1823.13 1657.39 1823.67 1658.06L1868.13 1708.45C1867.6 1724.6 1866.62 1741.12 1868.18 1757.41C1872.81 1807.75 1891.01 1855.73 1904.05 1904.29C1943.22 2038.18 1992.5 2207.76 2029.26 2341.78H2029.31Z"
      fill="black"
    />
    <path
      d="M1894.79 1702.35L1901.74 1635.49C1894.35 1640.83 1883.75 1647.29 1870 1652.1C1850.64 1658.86 1833.55 1659.39 1822.82 1658.86C1837.69 1675.6 1852.6 1692.33 1867.46 1709.07C1870.22 1709.65 1874.59 1710.23 1879.79 1709.29C1887.32 1707.96 1892.43 1704.31 1894.84 1702.3L1894.79 1702.35Z"
      fill="#2D2C2A"
    />
    <path
      d="M1894.57 1702.26C1895.42 1679.78 1896.84 1657.53 1900 1635.36L1902.72 1636.92C1896.8 1640.88 1890.57 1644.22 1884.16 1647.11C1864.93 1655.88 1843.96 1661.75 1822.69 1661L1824.38 1657.44C1839.65 1673.86 1854.47 1690.64 1868.76 1707.91L1867.82 1707.38C1872.27 1708.53 1877.03 1708.71 1881.62 1707.78C1886.2 1706.93 1890.79 1704.88 1894.62 1702.26H1894.57ZM1894.97 1702.48C1887.63 1709.51 1875.92 1713.52 1866.08 1710.31C1850.6 1694.07 1835.64 1677.38 1821.13 1660.29C1819.88 1658.95 1821.09 1656.59 1822.87 1656.81C1836.62 1657.66 1850.64 1656.9 1863.99 1653.25C1877.43 1649.74 1889.85 1642.84 1900.67 1634.2C1901.83 1633.27 1903.65 1634.34 1903.38 1635.76C1901.87 1658.15 1898.8 1680.49 1894.93 1702.53L1894.97 1702.48Z"
      fill="black"
    />
    <path
      d="M1792.11 1549.14C1678.3 1496.58 1564.44 1444.05 1450.63 1391.49C1457.93 1383.03 1465.18 1374.57 1472.48 1366.16C1571.3 1401.72 1670.06 1437.29 1768.88 1472.85L1792.11 1549.1V1549.14Z"
      fill="#4B4B4B"
    />
    <path
      d="M1792.25 1549.32C1678.7 1499.56 1562.57 1443.92 1450.19 1392.42C1449.61 1392.15 1449.43 1391.35 1449.83 1390.86L1471.42 1365.27C1471.77 1364.82 1472.4 1364.69 1472.89 1364.87L1547.26 1390.82C1613.45 1414.1 1703.67 1446.72 1769.32 1471.47C1769.77 1471.61 1770.12 1472.01 1770.21 1472.41C1776.44 1494.66 1786.1 1527.42 1792.2 1549.28L1792.25 1549.32ZM1791.93 1548.96C1785.12 1526.8 1774.35 1495.69 1767.45 1473.3L1768.34 1474.23C1675.01 1442.14 1564.44 1401.99 1472 1367.41L1473.46 1367.01L1451.34 1392.11L1450.99 1390.55C1479.25 1403.95 1507.69 1417.08 1536.09 1429.94C1618.88 1466.44 1709.63 1509.8 1791.89 1548.88L1791.93 1548.96Z"
      fill="black"
    />
    <path
      d="M1901.74 1635.54L1803.73 1376.62L1743.73 1397.54L1822.78 1658.91C1831.19 1659.66 1842.45 1659.71 1855.31 1657.04C1877.39 1652.45 1893.1 1642.17 1901.69 1635.54H1901.74Z"
      fill="#4B4B4B"
    />
    <path
      d="M1901.52 1635.45C1876.86 1574.2 1849.08 1503.7 1826.83 1441.61C1821.04 1425.45 1809.11 1392.87 1803.1 1376.84L1803.99 1377.24L1774.04 1387.84L1744.17 1398.61L1744.88 1397.18C1755.21 1429.63 1775.6 1494.97 1785.61 1527.51C1797.36 1565.88 1813.56 1620.31 1824.42 1658.42L1823 1657.26C1850.64 1660.24 1879.26 1651.83 1901.56 1635.45H1901.52ZM1901.96 1635.58C1879.97 1653.88 1851.13 1662.82 1822.64 1660.51C1821.93 1660.46 1821.4 1659.97 1821.22 1659.35C1809.11 1621.87 1792.56 1567.17 1780.98 1528.89C1771.28 1496.31 1752.01 1430.52 1742.66 1397.85C1742.48 1397.27 1742.79 1396.61 1743.37 1396.43L1773.46 1386.28L1803.5 1375.91C1803.86 1375.77 1804.26 1375.95 1804.39 1376.31C1810.54 1392.51 1823.22 1424.87 1829.68 1440.98C1854.07 1502.18 1879.97 1573.36 1902.01 1635.54L1901.96 1635.58Z"
      fill="black"
    />
    <path
      d="M1537.25 1471.96C1617.81 1446.15 1701.4 1417.17 1787.44 1384.54C1903.21 1340.61 2011.5 1294.68 2112.05 1248.3C2112.76 1245.71 2114.19 1239.08 2111.61 1231.29C2109.29 1224.22 2104.89 1219.94 2102.88 1218.21C2004.74 1262.14 1900.14 1305.63 1789.17 1347.55C1700.07 1381.2 1613.4 1411.38 1529.64 1438.45C1532.17 1449.66 1534.71 1460.83 1537.25 1472.05V1471.96Z"
      fill="#17130A"
    />
    <path
      d="M1537.38 1471.74C1730.51 1406.26 1928.89 1332.11 2110.98 1247.94C2113.83 1237.61 2110.27 1225.95 2102.08 1219.1L2103.41 1219.32C2010.21 1261.78 1915.8 1301.71 1820.11 1338.25C1724.5 1374.93 1627.6 1408.44 1529.86 1438.98L1530.3 1438.22L1537.38 1471.78V1471.74ZM1537.16 1472.14L1529.01 1438.49C1528.92 1438.18 1529.15 1437.82 1529.46 1437.73C1626.53 1405.24 1722.85 1370.52 1818.37 1333.76C1913.89 1296.9 2008.65 1258.18 2102.35 1216.96C2102.79 1216.74 2103.33 1216.87 2103.68 1217.18C2112.41 1224.66 2117.12 1238.68 2112.49 1249.28C1925.06 1334.47 1734.6 1413.12 1537.11 1472.14H1537.16Z"
      fill="black"
    />
    <path
      d="M2115.97 2325.8C2123.71 2322.72 2120.87 2297.32 2109.61 2269.07C2098.36 2240.82 2082.96 2220.42 2075.22 2223.5C2067.48 2226.58 2070.33 2251.98 2081.58 2280.23C2092.83 2308.48 2108.23 2328.88 2115.97 2325.8Z"
      fill="#4B4B4B"
    />
    <path
      d="M2109.69 2269.1C2115.52 2285.35 2123.67 2302.39 2121.22 2320.11C2118.42 2332.7 2108.18 2326.83 2102.79 2319.66C2085.35 2295.54 2071.77 2267.05 2069.05 2237.1C2069.14 2231.67 2068.65 2225.17 2074.53 2221.87C2090.91 2218.94 2104.4 2256.9 2109.74 2269.05L2109.69 2269.1ZM2109.38 2269.23C2106.98 2259.66 2072.21 2200.33 2072.66 2237.05C2073.19 2251.74 2077.65 2266.07 2082.41 2279.96C2087.57 2293.76 2094.07 2307.56 2104.22 2318.37C2106.98 2321.18 2111.43 2325.58 2115.39 2324.52C2124.29 2316.9 2113.65 2279.47 2109.38 2269.19V2269.23Z"
      fill="black"
    />
    <path
      d="M2029.35 2297.01L2089 2265.54C2089.53 2265.67 2093.49 2266.92 2095.23 2271.1C2096.92 2275.2 2095.14 2278.8 2094.91 2279.29C2075.02 2290.42 2055.17 2301.59 2035.27 2312.72L2029.35 2297.01Z"
      fill="#4B4B4B"
    />
    <path
      d="M2029.17 2296.96C2048 2284.9 2068.56 2274.8 2088.73 2265.09C2095.05 2266.07 2098.52 2274.08 2095.71 2279.74C2086.19 2286.24 2075.86 2291.71 2065.98 2297.63C2056.1 2303.33 2045.95 2308.63 2035.76 2313.79C2035.18 2314.06 2034.51 2313.83 2034.25 2313.3C2032.38 2307.69 2030.77 2302.08 2029.22 2296.92L2029.17 2296.96ZM2029.48 2297.1C2031.93 2302.48 2034.25 2307.42 2036.34 2312.36L2034.65 2311.74C2044.35 2305.78 2054.14 2299.9 2064.16 2294.43C2073.55 2289.57 2086.1 2282.14 2094.02 2278.89C2096.83 2274.17 2094.16 2267.59 2088.82 2266.07C2071.28 2276.8 2049.74 2288.24 2029.48 2297.1Z"
      fill="black"
    />
    <path
      d="M2038.37 2363.98C2046.29 2361.41 2045.11 2335.88 2035.73 2306.95C2026.35 2278.03 2012.32 2256.67 2004.39 2259.24C1996.47 2261.81 1997.64 2287.34 2007.03 2316.27C2016.41 2345.19 2030.44 2366.55 2038.37 2363.98Z"
      fill="#4B4B4B"
    />
    <path
      d="M2035.98 2306.76C2040.75 2323.31 2047.78 2340.89 2044.13 2358.43C2042.3 2365.6 2036.47 2367.65 2030.86 2362.57C2015.86 2350.24 1983.33 2269.68 2003.89 2257.44C2020.4 2255.57 2031.44 2294.34 2035.94 2306.8L2035.98 2306.76ZM2035.67 2306.84C2033.89 2297.14 2003.05 2235.67 2001.13 2272.35C2000.69 2287.04 2004.2 2301.64 2008.03 2315.79C2012.3 2329.9 2017.87 2344.1 2027.26 2355.58C2029.84 2358.57 2033.98 2363.28 2037.99 2362.44C2047.38 2355.45 2039.19 2317.35 2035.63 2306.84H2035.67Z"
      fill="black"
    />
    <path
      d="M1240.45 1332.51C1240.01 1341.99 1257.81 1349.87 1399.49 1388.42C1475.64 1409.16 1507.56 1416.95 1537.56 1398.92C1542.14 1396.16 1569.29 1379.78 1570.76 1355.21C1573.66 1306.43 1474 1243 1397.75 1259.07C1376.65 1263.52 1379.86 1269.31 1308.64 1299.26C1256.03 1321.38 1240.9 1323.83 1240.5 1332.47L1240.45 1332.51Z"
      fill="#2D2C2A"
      stroke="black"
      strokeWidth={1.04}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1544.9 1938.74C1481.34 1660.33 1361.83 1445.34 1278.02 1458.52C1194.2 1471.69 1177.74 1708.09 1241.3 1986.5C1304.86 2264.92 1424.37 2479.9 1508.18 2466.73C1591.99 2453.55 1608.46 2217.16 1544.9 1938.74ZM1502.39 2441.35C1501.37 2441.53 1500.3 2441.58 1499.14 2441.49C1494.56 2441.18 1452.45 2435.03 1387.91 2314.32C1341.04 2226.59 1299.29 2108.55 1270.36 1981.96C1240.09 1849.41 1227.19 1722.69 1233.95 1625.12C1241.39 1518.47 1269.78 1486.12 1283.8 1483.93C1284.83 1483.76 1285.94 1483.71 1287.1 1483.8C1291.68 1484.11 1333.79 1490.25 1398.33 1610.97C1445.2 1698.7 1486.95 1816.74 1515.88 1943.33C1546.15 2075.88 1559.05 2202.6 1552.29 2300.17C1544.86 2406.81 1516.46 2439.17 1502.44 2441.35H1502.39Z"
      fill="#17130A"
    />
    <path
      d="M1544.68 1938.83C1511.96 1801.87 1467.28 1664.83 1390.36 1545.98C1319.24 1438.8 1240.72 1417.3 1212.77 1565.7C1170.66 1790.03 1280.96 2268.34 1440.39 2433.61C1498.48 2490 1543.39 2466.24 1564.53 2393.64C1601.16 2250.89 1574.86 2081.53 1544.68 1938.83ZM1545.12 1938.74C1560.84 2007.73 1573.03 2077.66 1579.84 2148.12C1586.3 2218.62 1588.74 2290.06 1576.9 2360.26C1569.03 2402.01 1551.98 2468.19 1500.08 2469.57C1422.81 2464.95 1354.04 2320.06 1325.87 2256.5C1243.43 2062.75 1178.76 1774.54 1210.1 1565.21C1236.98 1415.12 1320.48 1432.52 1393.66 1543.76C1470.48 1663.31 1512.41 1801.52 1545.12 1938.74Z"
      fill="black"
    />
    <path
      d="M1502.44 2441.62C1454.72 2439.09 1398.55 2336.89 1377.9 2297.27C1279.35 2099.03 1216.59 1821.41 1235.38 1600.24C1239.96 1564.14 1245.48 1525.69 1266.85 1495.15C1272.63 1487.63 1281.98 1479.57 1292.26 1483.58C1342.25 1502.32 1384.89 1581.77 1409.23 1627.61C1505.2 1823.46 1572.41 2107.26 1550.6 2325.09C1546.64 2356.61 1535.42 2430.45 1502.44 2441.62ZM1502.35 2441.18C1534.8 2430.01 1545.97 2355.54 1549.31 2324.96C1568.05 2113.18 1499.32 1819.99 1405.58 1629.48C1385.38 1589.42 1330.98 1488.96 1284.03 1485.23C1277.93 1486.47 1272.77 1491.86 1268.76 1496.66C1259.77 1508.5 1254.29 1522.53 1249.75 1536.64C1231.73 1599.44 1231.37 1665.94 1234.58 1730.79C1240.94 1839.44 1261.33 1946.93 1289.9 2051.8C1313.31 2135.52 1341.36 2218.27 1379.77 2296.38C1399.66 2336.49 1455.08 2438.37 1502.39 2441.22L1502.35 2441.18Z"
      fill="black"
    />
    <path
      d="M1503.02 1584.62C1459.98 1702.13 1416.94 1819.63 1373.85 1937.14C1380.39 1952.01 1386.94 1966.83 1393.52 1981.7C1434.39 1867.39 1475.25 1753.09 1516.11 1638.79C1511.74 1620.72 1507.38 1602.69 1503.02 1584.62Z"
      fill="#4B4B4B"
    />
    <path
      d="M1503.02 1584.8C1480.72 1646.89 1429.67 1789.99 1406.79 1849.5C1395.93 1877.81 1384.62 1910.3 1374.52 1936.87L1394.41 1981.34H1392.63C1412.62 1924.1 1432.69 1866.81 1453.12 1809.66C1470.84 1759.9 1496.88 1688.77 1515.3 1638.52C1515.61 1640.75 1502.93 1584.44 1503.02 1584.8ZM1503.02 1584.44C1507.29 1602.33 1512.81 1620.54 1516.95 1639.1L1486.95 1725.05C1456.86 1810.95 1425.75 1896.55 1394.41 1982.05C1394.19 1982.85 1392.9 1982.9 1392.63 1982.1L1373.23 1937.41C1383.69 1907.81 1395.08 1878.3 1405.54 1848.52C1426.37 1788.34 1480.18 1645.95 1503.06 1584.4L1503.02 1584.44Z"
      fill="black"
    />
    <path
      d="M1644.2 2075.57C1554.12 2029.41 1463.98 1983.3 1373.89 1937.14C1380.44 1952.01 1386.98 1966.83 1393.57 1981.7C1479.61 2048.33 1565.64 2114.96 1651.68 2181.59C1649.19 2146.25 1646.74 2110.91 1644.25 2075.57H1644.2Z"
      fill="#4B4B4B"
    />
    <path
      d="M1644.02 2075.7C1605.75 2057.23 1546.06 2027.32 1507.69 2007.91C1462.65 1985.08 1418.49 1961 1373.58 1937.85L1374.6 1936.87C1381.06 1951.78 1387.78 1966.56 1394.37 1981.38C1455.88 2027.54 1527.05 2081.8 1588.61 2129.74C1610.15 2146.34 1631.43 2163.3 1652.75 2180.17L1649.9 2181.68C1647.76 2150.08 1645.72 2106.86 1644.02 2075.66V2075.7ZM1644.42 2075.43C1647.45 2105.92 1650.79 2150.39 1653.42 2181.46C1653.55 2182.93 1651.72 2183.95 1650.57 2182.97C1628.89 2166.55 1607.13 2150.17 1585.67 2133.48C1542.54 2100.32 1499.68 2066.8 1457.04 2033.02L1393.03 1982.36C1386.18 1967.54 1380.04 1952.32 1373.22 1937.41C1372.91 1936.78 1373.67 1936.07 1374.25 1936.43C1396.82 1947.91 1419.56 1959.17 1442.35 1970.43C1465.18 1981.65 1487.84 1993.18 1510.45 2004.75C1548.42 2024.56 1607.26 2055.09 1644.42 2075.35V2075.43Z"
      fill="black"
    />
    <path
      d="M1685.64 2103.48C1674.38 2035.73 1642.91 1984.54 1615.27 1989.13C1587.68 1993.71 1574.37 2052.33 1585.63 2120.03C1596.89 2187.78 1628.36 2238.97 1656 2234.38C1683.6 2229.8 1696.9 2171.18 1685.64 2103.48ZM1654.49 2225.26C1653.86 2225.35 1653.2 2225.43 1652.53 2225.43C1633.21 2225.43 1605.44 2182.48 1594.8 2118.57C1589.55 2086.96 1589.59 2056.56 1594.93 2032.97C1599.52 2012.76 1607.66 1999.81 1616.79 1998.3C1617.45 1998.21 1618.08 1998.12 1618.75 1998.12C1638.06 1998.12 1665.84 2041.07 1676.47 2104.94C1687.25 2169.89 1673.76 2222.01 1654.49 2225.21V2225.26Z"
      fill="#17130A"
      stroke="black"
      strokeWidth={1.04}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1767.98 1482.33C1776.08 1481.98 1785.92 1480.82 1796.83 1477.97C1813.65 1473.56 1826.74 1466.66 1835.73 1460.92C1834.13 1456.12 1832.52 1451.31 1830.92 1446.5C1822.82 1452.29 1809.87 1459.99 1792.46 1464.39C1781.69 1467.11 1772.08 1467.87 1764.51 1467.91C1765.67 1472.72 1766.83 1477.53 1768.03 1482.33H1767.98Z"
      fill="#A5A5A5"
    />
    <path
      d="M1768.12 1482.15C1791.17 1477.84 1815.03 1472.72 1835.28 1460.3L1834.97 1461.15C1833.59 1456.96 1831.23 1451.09 1829.63 1446.9L1831.63 1447.57C1813.03 1462.7 1788.5 1470.94 1764.47 1469.34L1765.85 1467.55C1766.65 1472.14 1767.27 1476.9 1768.07 1482.11L1768.12 1482.15ZM1767.85 1482.51C1766.34 1478.1 1764.6 1473.3 1763.09 1468.22C1762.87 1467.51 1763.27 1466.75 1764.02 1466.53C1787.44 1465.02 1810.22 1458.03 1830.17 1445.43C1830.88 1444.9 1831.95 1445.21 1832.17 1446.1L1834.22 1453.44C1834.93 1455.89 1835.73 1458.3 1836.49 1460.74C1836.57 1461.06 1836.49 1461.41 1836.17 1461.59C1816.1 1474.99 1792.64 1484.87 1767.9 1482.55L1767.85 1482.51Z"
      fill="black"
    />
    <path
      d="M2036.86 1234.67L2143.06 1189.59C2155.06 1184.49 2168.9 1190.08 2173.99 1202.09L2175.35 1205.28C2180.44 1217.29 2174.85 1231.12 2162.85 1236.21L2056.65 1281.29C2044.69 1286.37 2030.81 1280.8 2025.72 1268.8L2024.36 1265.6C2019.27 1253.59 2024.85 1239.76 2036.86 1234.67Z"
      fill="#17130A"
      stroke="black"
      strokeWidth={1.12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
      opacity={0.32}
    >
      <path
        d="M1791.8 1384.19C1824.42 1470.54 1857.05 1556.93 1889.67 1643.28C1893.68 1640.7 1897.73 1638.12 1901.74 1635.54C1869.51 1549.85 1837.33 1464.17 1805.11 1378.49C1800.65 1380.4 1796.2 1382.27 1791.8 1384.19Z"
        fill="#DAD8D4"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
      opacity={0.32}
    >
      <path
        d="M1889.63 1643.28C1900.36 1646.58 1916.87 1653.3 1932.67 1667.41C1963.47 1694.96 1969.48 1730.92 1970.91 1742.01C1974.42 1744.63 1977.9 1747.26 1981.41 1749.89C1978.83 1732.26 1969.71 1687.48 1931.69 1654.94C1921.5 1646.22 1911.09 1639.99 1901.74 1635.54C1897.73 1638.12 1893.68 1640.7 1889.68 1643.28H1889.63Z"
        fill="#DAD8D4"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
      opacity={0.32}
    >
      <path
        d="M1847.7 1689.13C1848.99 1702.04 1851.26 1721.53 1855.58 1745.03C1863.64 1789.19 1873.92 1821.28 1877.44 1832.41C1893.64 1883.77 1940.33 2041.92 2004.51 2259.04C1936.77 2018.91 1889.01 1843.89 1880.06 1810.55C1876.81 1798.49 1869.74 1771.43 1867.82 1735.42C1867.24 1724.02 1867.33 1714.77 1867.47 1709.07C1860.88 1702.39 1854.34 1695.76 1847.75 1689.08L1847.7 1689.13Z"
        fill="#DAD8D4"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
    >
      <path
        d="M1681.37 1400.66C1795.71 1355.61 1910.06 1310.52 2024.41 1265.48C2026.68 1261.16 2030.59 1254.71 2036.91 1248.3C2046.8 1238.28 2056.81 1234.19 2066.74 1229.96C2102.61 1214.6 2116.19 1206.19 2143.07 1200.45C2149.88 1198.98 2158.92 1197.42 2166.66 1202.41C2173.47 1206.81 2176.1 1214.29 2177.17 1218.12C2177.17 1213.36 2176.45 1203.12 2169.29 1194.53C2168.62 1193.73 2166.39 1191.06 2163.72 1189.28C2152.46 1181.84 2135.33 1191.99 2089.35 1211.22C2081.82 1214.38 2074.35 1217.72 2066.74 1220.7C2045.33 1229.2 2036.69 1231.25 2028.41 1240.02C2023.87 1244.87 2021.29 1249.77 2019.91 1252.79C1966.63 1275.71 1911.57 1298.5 1854.78 1320.94C1795.58 1344.31 1737.54 1366.12 1680.79 1386.46C1681.01 1391.17 1681.23 1395.85 1681.46 1400.57L1681.37 1400.66Z"
        fill="#DAD8D4"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
    >
      <path
        d="M2038.87 1842.73C2041.99 1841.44 2121.31 1989.8 2181.08 2190.72C2260.58 2457.91 2263.78 2677.84 2260.18 2678.33C2256.8 2678.77 2248.16 2487.47 2174.54 2223.48C2111.34 1996.78 2035.76 1843.98 2038.87 1842.69V1842.73Z"
        fill="#DAD8D4"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
      opacity={0.32}
    >
      <path
        d="M1799.63 1587.78C1780.27 1646.67 1761.27 1707.29 1742.84 1769.51C1715.55 1861.7 1691.16 1951.47 1669.44 2038.67C1666.24 2032.26 1663.03 2025.85 1659.83 2019.44L1790.02 1558.94L1799.63 1587.78Z"
        fill="#DAD8D4"
      />
    </g>

    <g id="frog" className={shaking ? 'shaking' : ''}>
      <image x="510" y="468" width="1224" height="2284" xlinkHref="/img/frog/2.silver.png" />
    </g>

    <path d="M2497.68 2202.25L2437.59 2203.27L2396.51 2236.34L2535.29 2238.61L2497.68 2202.25Z" fill="#5C5B16" />
    <path
      d="M2497.59 2202.51C2477.38 2204.25 2457.67 2204.92 2437.64 2205.54L2439.02 2205.05C2425.44 2216.18 2410.98 2226.24 2397.04 2237.01L2396.55 2235.54C2419.7 2236.3 2442.84 2235.27 2465.99 2235.27C2489.14 2235.27 2512.28 2235.36 2535.38 2236.38L2533.73 2240.35C2521.45 2227.97 2509.52 2215.55 2497.64 2202.56L2497.59 2202.51ZM2497.77 2202.07C2510.95 2213.28 2524.08 2225.12 2536.89 2237.01C2538.41 2238.3 2537.38 2241.01 2535.38 2240.97C2494.83 2241.46 2454.33 2239.14 2413.82 2237.54C2408.04 2237.36 2402.25 2237.23 2396.47 2237.23C2395.71 2237.23 2395.35 2236.21 2395.98 2235.76C2410 2224.59 2422.73 2211.01 2437.5 2201.09C2457.53 2201 2477.92 2201 2497.73 2202.07H2497.77Z"
      fill="black"
    />
    <path
      d="M2609.13 2228.15L2854.16 2224.01L2779.56 2188.27L2783.79 2165.08L2672.52 2148.43L2645.36 2182.57L2609.13 2228.15Z"
      fill="#BABAB3"
    />
    <path
      d="M2609.36 2228.02C2645.59 2226.73 2697.26 2224.99 2732.78 2224.19C2773.29 2223.34 2813.57 2224.37 2854.16 2223.12L2853.81 2224.77C2828.88 2212.97 2803.64 2201.84 2778.67 2190.14C2777.83 2189.74 2777.34 2188.8 2777.52 2187.91C2777.52 2187.91 2781.52 2164.68 2781.52 2164.63L2783.48 2167.35C2746.36 2162.14 2709.24 2156.67 2672.2 2150.75L2674.39 2149.9C2653.15 2176.38 2631.39 2201.76 2609.36 2228.02ZM2608.91 2228.24C2628.1 2202.6 2650.53 2172.65 2670.69 2146.96C2671.23 2146.3 2672.07 2145.98 2672.87 2146.12C2691.48 2148.61 2709.99 2151.5 2728.55 2154.17C2747.12 2156.84 2765.63 2159.92 2784.15 2162.76C2785.44 2162.94 2786.33 2164.14 2786.11 2165.39C2786.15 2165.43 2781.61 2188.58 2781.61 2188.62L2780.45 2186.35C2805.2 2198.51 2829.73 2211.19 2854.52 2223.21C2854.96 2223.43 2855.14 2223.92 2854.92 2224.37C2774.67 2227.62 2689.7 2228.73 2608.87 2228.24H2608.91Z"
      fill="black"
    />
    <path
      d="M2460.29 2179.77L2484.37 2234.87L2532.71 2232.73L2655.34 2227.35L2579.58 2122.26L2473.78 2156.4L2472.89 2157.96L2460.29 2179.77Z"
      fill="#8FC77C"
    />
    <path
      d="M2460.56 2179.77C2469.37 2197.93 2477.79 2215.87 2486.11 2234.07L2484.28 2232.96C2509.52 2231.53 2544.86 2229.8 2569.79 2229.49C2598.32 2229.04 2626.85 2228.06 2655.29 2226.32L2654.49 2227.93C2628.5 2193.43 2602.64 2158.8 2577.44 2123.77L2580.38 2124.75C2545.13 2136.15 2509.7 2147.1 2474.14 2157.51L2474.8 2156.98L2460.56 2179.77ZM2460.07 2179.77L2472.8 2155.86C2472.93 2155.6 2473.16 2155.42 2473.47 2155.33C2508.41 2143 2543.57 2131.21 2578.82 2119.81C2579.94 2119.46 2581.09 2119.86 2581.76 2120.79C2607.04 2155.82 2631.7 2191.25 2656.23 2226.81C2656.72 2227.44 2656.23 2228.46 2655.43 2228.42C2626.89 2229.17 2598.41 2230.73 2569.97 2232.78C2545.22 2234.78 2509.61 2235.89 2484.46 2236.78C2483.66 2236.83 2482.95 2236.34 2482.64 2235.63C2474.89 2217.11 2467.28 2198.33 2460.07 2179.77Z"
      fill="black"
    />
    <path
      d="M2460.29 2179.77L2484.37 2234.87L2532.71 2232.73L2472.89 2157.96L2460.29 2179.77Z"
      fill="#6F915F"
      stroke="black"
      strokeWidth={1.12}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2678.39 2222.32L2668.78 2199.4L2617.81 2195.61L2613.45 2209.54L2567.12 2205.72L2549 2229.13L2678.39 2222.32Z"
      fill="#5C5B16"
    />
    <path
      d="M2678.17 2222.19C2674.92 2215.78 2670.33 2206.3 2667.31 2199.97L2668.6 2200.91C2651.6 2200.2 2634.59 2199.31 2617.63 2197.62L2619.73 2196.19C2619.68 2196.37 2614.83 2209.94 2614.79 2210.08C2614.56 2210.7 2613.94 2211.06 2613.32 2211.01C2605.62 2210.08 2597.92 2209.1 2590.22 2208.43C2582.52 2207.72 2574.77 2207.19 2567.03 2206.74L2567.92 2206.34L2550.56 2230.38L2548.87 2227.17C2559.5 2226.32 2570.1 2225.66 2580.6 2224.99C2610.42 2223.08 2648.04 2222.76 2678.12 2222.19H2678.17ZM2678.61 2222.45C2636.64 2226.46 2591.02 2230.2 2549 2231.09C2547.4 2231.18 2546.37 2229.08 2547.44 2227.88L2566.31 2205.05C2581.72 2205.36 2597.83 2207.99 2613.54 2208.03L2612.03 2209.05L2615.9 2194.94C2616.12 2194.05 2616.97 2193.48 2617.86 2193.52C2626.4 2193.88 2634.95 2194.5 2643.45 2195.3C2651.95 2196.06 2660.5 2196.73 2669 2197.79C2669.53 2197.84 2669.98 2198.24 2670.16 2198.73C2671.67 2202.82 2673.27 2206.92 2674.56 2210.88L2678.57 2222.41L2678.61 2222.45Z"
      fill="black"
    />
    <path
      d="M2401.05 2231.62L2409.2 2282.99L2409.24 2283.12L2473.51 2688.3L2584.12 2688.08L2778.72 2687.76L2855.68 2307.65L2859.64 2288.15V2288.11L2872.68 2223.7L2401.05 2231.62Z"
      fill="#FFB53D"
    />
    <path
      d="M2401.27 2231.8C2426.11 2376.5 2452.95 2542.3 2475.38 2687.99L2473.51 2686.43L2626.14 2687.1C2664.15 2687.1 2740.75 2687.1 2778.76 2686.74C2800.97 2561.71 2845.93 2346.95 2870.14 2223.21L2872.77 2226.32L2755.35 2228.15C2680.62 2229 2478.63 2231.04 2401.27 2231.84V2231.8ZM2400.87 2231.49C2479.25 2229.62 2678.44 2224.9 2754.24 2223.25L2872.63 2221.12C2874.28 2221.07 2875.57 2222.63 2875.26 2224.23C2871.12 2245.91 2788.29 2646.82 2779.79 2687.99C2779.7 2688.48 2779.25 2688.83 2778.76 2688.83L2702.47 2688.7C2654 2688.74 2523.67 2689.77 2473.56 2690.21C2472.62 2690.21 2471.86 2689.55 2471.69 2688.66L2455.26 2587.39C2437.95 2477.05 2416.76 2341.56 2400.87 2231.58V2231.49Z"
      fill="black"
    />
    <path d="M2891.06 2218.76L2878.38 2288.33L2388.05 2282.72L2377.86 2227.39L2891.06 2218.76Z" fill="#FFB53D" />
    <path
      d="M2891.28 2218.58C2887.32 2241.55 2883.76 2264.96 2879.67 2288.55C2879.58 2289.17 2879 2289.62 2878.38 2289.62C2847.75 2289.62 2786.37 2289.35 2755.8 2289.04C2637.71 2288.06 2506.81 2283.97 2388.05 2284.06C2387.39 2284.06 2386.85 2283.57 2386.76 2282.94L2376.17 2227.7C2375.95 2226.64 2376.79 2225.61 2377.86 2225.61C2546.42 2221.03 2722.32 2219.74 2891.28 2218.58ZM2890.88 2218.89C2766.34 2222.1 2629.39 2226.06 2505.69 2227.66C2463.14 2228.33 2420.55 2228.82 2377.9 2229.17L2379.6 2227.08L2389.34 2282.5L2388.05 2281.38L2510.63 2283.34C2592.35 2284.1 2674.12 2283.34 2755.8 2284.81C2786.28 2285.21 2847.89 2286.33 2878.38 2287.04L2877.09 2288.11C2881.45 2265.32 2886.34 2242.35 2890.88 2218.89Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
      opacity={0.52}
    >
      <path
        d="M2881.89 2223.52L2873.43 2269.95L2554.34 2270.66L2549 2229.13L2881.89 2223.52Z"
        fill="url(#paint3_linear_2458_259)"
      />
    </g>
    <path
      d="M2815.3 2215.11L2768.61 2188.18L2772.93 2169.57L2717.34 2165.88L2703.85 2189.25L2741.33 2216.35L2815.3 2215.11Z"
      fill="#A2A49B"
    />
    <path
      d="M2815.08 2215.06C2798.75 2206.12 2783.17 2199.13 2767.5 2190.09C2766.61 2189.6 2766.21 2188.62 2766.43 2187.69C2767.86 2181.5 2769.33 2175.32 2771.06 2169.17L2772.8 2171.53C2754.33 2169.44 2735.85 2167.48 2717.29 2166.95L2718.27 2166.41C2714 2174.34 2709.9 2182.35 2705.59 2190.23L2705.05 2187.6C2711.42 2191.92 2717.74 2196.37 2724.06 2200.78C2730.38 2205.18 2736.7 2209.59 2742.93 2214.17L2741.33 2213.68C2765.41 2213.19 2790.82 2215.02 2815.13 2215.06H2815.08ZM2815.57 2215.2C2791.71 2215.69 2763.23 2219.92 2739.77 2218.54C2727.26 2209.46 2714.89 2200.2 2702.6 2190.81C2701.8 2190.18 2701.63 2189.11 2702.12 2188.27C2706.79 2180.61 2711.68 2173.05 2716.4 2165.39C2716.58 2165.03 2716.98 2164.81 2717.38 2164.86C2722.01 2165.39 2726.64 2165.61 2731.27 2166.01C2745.16 2167.21 2759.09 2167.26 2773.02 2167.66C2774.22 2167.66 2775.07 2168.86 2774.76 2170.02C2773.6 2176.25 2772.17 2182.48 2770.75 2188.67L2769.68 2186.22C2785.17 2195.12 2800.75 2206.07 2815.53 2215.2H2815.57Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.41}
    >
      <path
        d="M2859.64 2288.11L2482.5 2282.54L2478.14 2225.39L2377.95 2228.06L2387.61 2282.54L2409.24 2283.12L2473.73 2688.52L2539.61 2688.17L2502.71 2305.38L2856.88 2301.86L2859.64 2288.11Z"
        fill="#64103D"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.41}
    >
      <path
        d="M2558.08 2328.21H2597.65L2613.45 2672.72H2578.29L2597.65 2670.09L2586.21 2331.73L2558.08 2328.21Z"
        fill="#64103D"
      />
    </g>
    <path d="M2573.04 2672.72L2558.08 2330.88L2563.38 2672.72H2573.04Z" fill="#EADFBA" />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.41}
    >
      <path
        d="M2675.5 2329.01L2715.07 2329.14L2699.05 2670.14L2663.88 2670.05L2683.24 2667.47L2703.63 2332.62L2675.5 2329.01Z"
        fill="#64103D"
      />
    </g>
    <path d="M2658.63 2670.05L2675.86 2329.1L2648.97 2670.01L2658.63 2670.05Z" fill="#EADFBA" />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.41}
    >
      <path
        d="M2774.8 2329.01L2814.37 2329.14L2774.62 2670.14L2739.46 2670.05L2758.82 2667.47L2802.93 2332.62L2774.8 2329.01Z"
        fill="#64103D"
      />
    </g>
    <path d="M2734.21 2670.05L2770.75 2330.88L2724.55 2670.01L2734.21 2670.05Z" fill="#EADFBA" />
    <path
      d="M2778.67 2688.52L2790.11 2597.14L2784.81 2579.56L2810.05 2365.87L2794.47 2574.26L2797.99 2578.67L2852.47 2323.36L2778.67 2688.52Z"
      fill="#EADFBA"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2458_259"
        x1={3247.59}
        y1={1944.71}
        x2={2397.49}
        y2={1838.42}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#668788" />
        <stop offset={1} stopColor="#403E50" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2458_259"
        x1={2432.34}
        y1={2519.83}
        x2={2840.59}
        y2={2822.27}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00813E" />
        <stop offset={0.16} stopColor="#006832" />
        <stop offset={0.55} stopColor="#003017" />
        <stop offset={0.84} stopColor="#000D06" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2458_259"
        x1={2663.66}
        y1={1974.31}
        x2={2089.79}
        y2={1889.83}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00813E" />
        <stop offset={0.16} stopColor="#006832" />
        <stop offset={0.55} stopColor="#003017" />
        <stop offset={0.84} stopColor="#000D06" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2458_259"
        x1={2592.4}
        y1={2246.89}
        x2={3208.95}
        y2={2247.87}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#130800" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
))`
  flex-shrink: 0;
  margin: 60px auto;
  width: 813px;
  height: 820px;

  .shaking {
    transform-origin: 1122px 1610px;
    animation: ${shakeAnimation} 0.2s infinite;
  }
`;

export default Silver;

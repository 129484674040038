import styled from 'styled-components';

const UnlockFrog = styled((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="466" height="298" viewBox="0 0 466 298" fill="none" {...props}>
    <path
      d="M67.2216 109.932C62.7888 109.338 29.9909 105.399 12.1423 126.457C-5.37115 147.113 3.41908 177.774 5.84918 186.263C25.1978 253.794 134.787 289.474 214.376 295.642C312.376 303.234 436.018 271.592 459.891 204.363C462.196 197.877 472.285 169.461 457.528 147.708C439.948 121.798 400.144 124.555 396.147 124.89C418.462 89.6202 408.734 43.6495 377.26 21.8038C342.928 -2.01946 291.385 8.89082 268.668 48.56C246.898 49.0879 225.127 49.6075 203.357 50.1354C187.57 11.1617 144.633 -7.86006 108.935 4.48314C69.35 18.1671 47.4624 67.3807 67.23 109.924L67.2216 109.932Z"
      fill="#88BC3A"
    />
    <path
      d="M67.3138 109.991C16.6338 104.93 -8.28721 135.75 6.0671 184.553C7.4665 189.313 9.20947 194.097 11.3379 198.572C12.6703 201.061 14.1535 204.094 15.7121 206.424C38.8985 243.227 84.5675 264.109 124.639 277.131C205.067 302.706 294.134 302.999 373.858 274.575C419.099 257.699 464.609 228.052 464.768 174.514C464.534 147.364 445.721 131.225 420.105 126.977C411.658 125.485 402.994 125.15 394.471 125.896L395.402 124.412C430.739 66.5427 383.017 -5.3127 315.217 13.3906C295.919 18.8374 279.101 32.1275 269.297 49.6074L268.702 49.6242L203.391 51.1241L202.704 51.1409C191.877 22.7423 163.37 2.12003 132.876 1.55022C77.4197 0.486005 44.9151 61.7327 67.3138 109.991ZM67.1462 109.865C48.8786 72.5257 64.7581 23.4294 103.128 6.09198C131.418 -7.03053 166.504 1.9692 187.629 24.1668C194.743 31.5242 200.416 40.2641 204.287 49.7499L203.34 49.1298L268.651 47.4874L267.755 48.0237C288.017 11.6225 337.44 -3.47756 373.648 18.2257C400.421 33.795 413.703 67.1711 407.979 97.1702C406.169 107.167 402.357 116.787 396.91 125.351L396.089 123.993C423.934 122.049 457.755 131.669 464.601 162.372C468.154 178.352 464.592 195.12 458.207 209.918C449.819 228.513 434.359 242.9 417.507 253.886C390.265 271.408 359.026 281.665 327.56 288.477C248.607 305.228 164.258 298.424 90.0646 266.07C59.1353 252.076 27.8206 232.719 11.4384 201.916C2.06164 183.656 -3.25943 156.464 5.06993 137.057C15.0585 113.1 43.6581 106.522 67.1546 109.874L67.1462 109.865Z"
      fill="black"
    />
    <path
      d="M106.011 116.829C136.75 116.829 161.668 91.9102 161.668 61.1714C161.668 30.4326 136.75 5.51392 106.011 5.51392C75.2721 5.51392 50.3534 30.4326 50.3534 61.1714C50.3534 91.9102 75.2721 116.829 106.011 116.829Z"
      fill="#FF7900"
    />
    <path
      d="M161.769 61.1715C162.012 91.8493 137.049 118.187 106.003 117.7C75.5426 117.608 49.859 91.556 49.7752 61.1715C49.13 21.0163 90.7433 -6.93819 127.689 8.8239C148.32 17.346 162.029 38.999 161.769 61.1715ZM161.56 61.1715C161.803 31.1891 135.725 6.27649 106.003 6.62844C76.1208 6.41057 51.0908 31.3232 50.9149 61.1715C50.521 100.849 90.6511 126.608 126.952 111.751C147.18 103.723 161.828 83.1345 161.56 61.1715Z"
      fill="black"
    />
    <path
      d="M360.291 122.569C390.808 122.569 415.546 97.8303 415.546 67.3137C415.546 36.797 390.808 12.0583 360.291 12.0583C329.774 12.0583 305.036 36.797 305.036 67.3137C305.036 97.8303 329.774 122.569 360.291 122.569Z"
      fill="#FF7900"
    />
    <path
      d="M415.647 67.3137C415.89 97.7736 391.111 123.926 360.283 123.44C330.032 123.348 304.541 97.472 304.449 67.3137C303.804 27.4434 345.124 -0.318375 381.81 15.3348C402.298 23.7982 415.907 45.3004 415.647 67.3137ZM415.437 67.3137C415.68 37.5492 389.787 12.8209 360.283 13.1728C330.619 12.955 305.773 37.6833 305.597 67.3137C305.203 106.706 345.04 132.273 381.081 117.524C401.167 109.555 415.705 89.1175 415.437 67.3137Z"
      fill="black"
    />
    <path
      d="M63.4509 56.7134C65.7302 62.7049 75.9282 63.9702 89.8803 65.0344C121.681 67.4478 140.133 68.7047 142.194 62.0764C142.597 60.7943 142.236 59.6463 142.01 58.934C136.295 41.2614 68.2105 38.1106 63.2665 52.0963C62.5291 54.1828 63.3084 56.3364 63.4509 56.7134Z"
      fill="#0C1D2B"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.53">
      <path
        d="M305.027 67.3135C305.078 69.0733 306.527 100.086 333.2 115.237C353.964 127.035 380.142 123.616 397.036 108.843C415.018 93.1228 415.521 70.6235 415.546 67.3135C415.63 54.124 410.971 44.1187 408.248 39.2083C410.72 65.16 394.564 89.3185 370.179 96.7512C344.671 104.527 316.591 92.0502 305.027 67.3135Z"
        fill="#FF7900"
      />
    </g>
    <path
      d="M319.6 62.3696C321.946 68.537 332.446 69.8442 346.817 70.9335C379.564 73.4139 398.569 74.7128 400.681 67.8917C401.092 66.5677 400.731 65.3862 400.488 64.6572C394.606 46.4566 324.502 43.2137 319.407 57.6183C318.644 59.7719 319.449 61.9925 319.6 62.3779V62.3696Z"
      fill="#0C1D2B"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.53">
      <path
        d="M53.8478 40.6831C45.9877 62.7886 52.2891 87.3912 69.5847 102.458C86.4781 117.164 110.192 119.636 129.097 111.214C147.993 102.793 155.937 86.1929 157.914 81.7098C138.038 98.7875 110.226 102.475 87.7853 91.1536C58.0208 76.129 54.0824 42.9624 53.8478 40.6915V40.6831Z"
        fill="#FF7900"
      />
    </g>
    <path
      d="M109.606 17.7314C110.092 15.5276 118.254 14.2623 125.51 16.3069C142.731 21.1671 154.018 44.5044 149.107 51.4762C148.764 51.9622 148.261 52.6829 147.373 52.9007C142.94 54.0152 139.597 40.038 127.84 29.6221C118.371 21.2341 109.07 20.1448 109.606 17.723V17.7314Z"
      fill="white"
    />
    <path
      d="M154.328 59.8642C155.124 63.9786 150.825 68.5203 148.571 67.9756C146.728 67.5315 145.915 63.6098 146.359 60.744C146.418 60.3586 147.205 55.6576 149.803 55.3308C151.814 55.0794 153.884 57.5765 154.328 59.8558V59.8642Z"
      fill="white"
    />
    <path
      d="M361.581 26.1444C361.808 23.8986 369.777 21.7116 377.218 22.9182C394.882 25.7841 408.75 47.68 404.678 55.1631C404.393 55.6826 403.974 56.4535 403.119 56.7803C398.837 58.3892 393.927 44.8896 381.056 35.8815C370.69 28.6331 361.33 28.608 361.581 26.1444Z"
      fill="white"
    />
    <path
      d="M410.82 62.906C412.077 66.9031 408.331 71.9057 406.027 71.6208C404.15 71.3862 402.885 67.5818 403.002 64.6908C403.019 64.3054 403.262 59.5374 405.809 58.9257C407.778 58.448 410.125 60.6938 410.82 62.906Z"
      fill="white"
    />
    <path
      d="M205.921 113.276C205.025 111.399 198.271 111.6 193.922 115.044C190.168 118.019 188.19 123.424 189.598 124.781C191.19 126.323 197.031 122.527 198.254 121.731C201.346 119.72 206.877 115.262 205.93 113.276H205.921Z"
      fill="#0C1D2B"
    />
    <path
      d="M234.521 128.518C236.365 127.555 235.904 120.809 232.292 116.594C229.175 112.957 223.695 111.189 222.396 112.639C220.913 114.29 224.935 119.98 225.773 121.17C227.901 124.186 232.569 129.541 234.521 128.51V128.518Z"
      fill="#0C1D2B"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.35">
      <path
        d="M313.524 97.0447C325.415 114.499 345.526 124.203 365.855 122.125C391.631 119.494 405.29 99.1898 407.066 96.4497C405.968 100.883 404.477 105.827 402.44 111.106C400.438 116.301 398.267 120.901 396.147 124.873C423.18 121.681 448.185 130.739 459.296 149.267C467.55 163.026 465.816 178.16 464.902 186.154C461.718 213.982 444.397 231.839 438.046 237.797C454.495 179.442 446.945 158.777 436.671 150.842C414.842 133.999 373.154 168.808 340.967 149.56C322.306 138.398 315.996 114.55 313.524 97.0363V97.0447Z"
        fill="#559D00"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.35">
      <path
        d="M139.421 1.13965C156.28 24.787 171.238 45.3757 183.682 62.3194C203.617 89.4694 208.301 95.31 217.125 96.944C224.248 98.2596 236.616 97.3127 290.304 45.7947C304.818 31.8678 316.625 19.7424 325.122 10.7762C318.401 11.6561 307.03 14.0359 295.022 21.4016C280.19 30.4935 272.204 42.401 268.66 48.5516C246.889 49.0795 225.119 49.5991 203.349 50.127C200.458 43.2054 194.575 31.7588 183.087 21.3597C166.076 5.9328 147.147 2.22062 139.421 1.13965Z"
        fill="#559D00"
      />
    </g>
    <path
      d="M164.601 65.0762C165.004 65.2102 164.233 93.458 143.845 109.731C121.446 127.613 90.0478 119.326 90.0394 118.974C90.0394 118.63 120.424 125.569 142.219 108.55C162.448 92.7457 164.191 64.9421 164.593 65.0762H164.601Z"
      fill="#0C1D2B"
    />
    <path
      d="M317.949 25.3401C317.521 25.072 299.949 41.5296 299.061 65.1769C298.24 87.0897 312.175 103.748 312.636 103.539C313.089 103.329 300.511 87.1232 301.424 65.1769C302.405 41.605 318.368 25.5999 317.949 25.3401Z"
      fill="#0C1D2B"
    />
    <path
      d="M53.9987 62.2272C53.6635 62.2859 52.3898 81.7518 64.9173 95.2765C72.1071 103.036 81.3414 106.145 81.5928 105.751C81.8526 105.332 71.864 101.243 64.1799 91.4386C53.5378 77.8552 54.3003 62.1686 53.9987 62.2272Z"
      fill="#0C1D2B"
    />
    <path
      d="M409.136 88.3382C409.479 88.6315 401.175 105.341 383.017 114.013C367.774 121.295 352.841 119.452 352.766 118.882C352.699 118.295 368.679 119.351 385.371 110.176C401.519 101.302 408.817 88.0785 409.128 88.3382H409.136Z"
      fill="#0C1D2B"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M203.357 50.1271C203.357 51.3589 203.751 75.2325 217.326 81.9949C230.918 88.7656 256.685 77.8553 268.668 48.5518C246.898 49.0797 225.127 49.5992 203.357 50.1271Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M269.9 84.936C274.09 87.6175 281.732 91.0782 283.634 88.9163C284.33 88.1286 284.288 86.5616 278.573 75.9027C273.084 65.6628 270.31 60.5512 268.45 60.7188C264.487 61.0792 260.087 73.2045 264.47 80.2434C265.744 82.2796 267.419 83.3606 269.891 84.9444L269.9 84.936Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M184.134 88.0951C190.226 83.4528 189.43 71.7967 184.134 68.8638C179.785 66.4589 172.219 69.8191 169.411 75.5089C167.853 78.6764 167.015 84.098 170.358 87.6174C173.978 91.4218 180.472 90.8771 184.126 88.0951H184.134Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M199.746 94.4385C201.958 93.2821 200.994 84.5505 198.078 83.7209C196.31 83.2181 193.427 85.5142 193.075 88.2459C192.547 92.3436 197.977 95.3519 199.746 94.4385Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M192.355 78.2658C195.254 79.3635 200.759 75.5927 201.782 70.4476C202.729 65.7047 199.754 60.3083 196.033 59.6379C193.997 59.2692 192.321 60.4256 192.12 60.5596C187.092 64.1042 188.165 76.6821 192.346 78.2658H192.355Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M253.132 96.8771C257.523 94.0448 258.855 87.1148 256.518 85.0199C254.666 83.3608 250.669 84.8775 248.607 86.1512C244.702 88.5561 241.485 93.9023 243.244 96.6006C244.778 98.9553 249.881 98.9804 253.132 96.8855V96.8771Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M358.129 151.412C368.218 158.585 389.771 146.325 389.36 138.708C389.075 133.462 378.316 129.097 369.777 129.18C367.514 129.206 356.378 129.314 353.369 136.588C351.266 141.674 353.906 148.403 358.137 151.412H358.129Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.2">
      <path
        d="M124.622 146.342C134.116 142.102 144.088 132.658 142.001 127.035C140.678 123.457 134.778 122.292 131.384 122.209C115.798 121.815 101.369 142.797 104.838 148.278C107.226 152.057 118.522 149.074 124.631 146.35L124.622 146.342Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M441.791 134.125C441.716 148.554 437.56 156.071 433.529 160.286C431.887 162.004 430.814 162.599 429.792 164.61C425.518 173.006 433.077 184.444 437.266 190.771C441.507 197.181 445.906 203.625 453.397 204.74C456.02 205.134 458.299 204.765 459.891 204.346C462.037 198.983 464.693 190.721 465.397 180.347C465.673 176.35 466.101 169.989 464.609 163.428C460.964 147.431 447.565 137.77 441.791 134.116V134.125Z"
        fill="#841B24"
      />
    </g>
    <path
      d="M21.5861 214.887C22.3319 212.566 23.6978 209.181 26.3039 205.737C29.53 201.48 35.379 196.343 47.3451 193.151C65.8557 188.198 78.5174 194.047 95.5532 194.332C122.393 194.776 125.2 180.833 175.813 166.001C194.785 160.445 204.271 157.663 213.974 157.345C248.088 156.213 255.881 178.436 306.435 194.332C326.806 200.734 341.135 205.242 360.735 203.575C382.757 201.706 391.731 193.72 410.309 195.313C429.398 196.947 444.238 207.455 453.984 216.362C448.294 225.396 442.395 232.116 437.752 236.775C431.208 243.353 425.417 247.585 421.814 250.199C419.594 251.808 412.974 256.517 402.34 262.149C386.201 270.687 372.643 275.095 361.917 278.522C361.917 278.522 348.199 282.905 334.322 286.047C264.822 301.776 196.318 293.279 196.318 293.279C196.318 293.279 142.043 286.55 93.9694 265.936C93.9694 265.936 69.8193 255.579 47.2446 239.473C39.8286 234.177 34.8511 229.476 33.4768 228.161C28.1642 223.066 24.2257 218.34 21.5778 214.879L21.5861 214.887Z"
      fill="#FFD9AC"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.29">
      <path
        d="M133.521 109.731C126.885 117.189 116.93 126.717 103.028 134.719C72.2411 152.442 48.9373 143.401 40.8594 157.537C33.1669 171.012 46.5659 192.162 56.9902 208.686C72.1992 232.795 90.8104 246.395 101.059 253.735C127.195 272.464 155.945 282.737 168.925 287.38C178.135 290.673 183.799 292.223 183.682 292.692C183.372 293.874 145.948 287.949 105.383 271.643C88.397 264.813 72.2411 258.193 53.8394 244.493C39.041 233.482 14.4468 215.063 4.85209 182.919C1.99463 173.333 -3.46889 155.032 5.43866 137.275C11.2709 125.661 20.5807 119.594 24.0331 117.407C42.5353 105.667 62.0766 108.91 67.2133 109.932C65.5123 105.541 63.8028 101.142 62.1017 96.7512C65.9899 101.201 74.0846 109.178 86.8887 113.569C109.589 121.362 129.399 111.826 133.513 109.731H133.521Z"
        fill="#A27E00"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M12.1424 126.457C14.6228 132.256 17.4886 140.108 19.617 149.669C20.5388 153.817 22.9521 165.598 22.7678 181.143C22.6169 193.293 20.9242 203.927 18.8293 212.617C14.12 205.175 -9.36814 166.596 6.23474 135.499C7.72632 132.524 9.65363 129.474 12.134 126.449L12.1424 126.457Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.29">
      <path
        d="M178.093 245.28C189.564 242.364 195.112 259.45 211.93 262.199C227.868 264.805 233.616 251.205 251.666 250.392C270.696 249.537 283.802 263.766 292.19 269.673C311.17 283.03 350.328 284.933 438.951 234.261C433.982 238.954 426.138 245.884 415.739 253.149C366.509 287.547 315.133 290.924 278.028 292.885C251.582 294.284 225.873 292.759 199.335 282.653C188.793 278.64 167.04 270.36 167.073 258.654C167.09 253.006 172.168 246.78 178.093 245.28Z"
        fill="#A27E00"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M122.703 142.982C130.438 139.521 138.566 131.828 136.865 127.245C135.784 124.329 130.974 123.382 128.209 123.306C115.505 122.988 103.748 140.082 106.572 144.549C108.517 147.632 117.726 145.202 122.703 142.973V142.982Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M182.115 85.3383C187.159 81.492 186.506 71.8303 182.115 69.4002C178.512 67.4059 172.235 70.1879 169.914 74.9056C168.624 77.5285 167.928 82.02 170.702 84.9361C173.702 88.0952 179.081 87.6427 182.115 85.3299V85.3383Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M192.346 78.2573C194.827 79.1958 199.536 75.9697 200.416 71.5704C201.22 67.5146 198.681 62.8975 195.497 62.3276C193.754 62.0176 192.321 62.998 192.154 63.1153C187.855 66.1488 188.768 76.9082 192.346 78.2657V78.2573Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M198.832 93.0139C200.659 92.0586 199.863 84.8437 197.458 84.165C196 83.7544 193.62 85.6482 193.327 87.9023C192.891 91.2877 197.382 93.7764 198.832 93.0139Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M268.97 81.7014C272.389 83.8885 278.631 86.7208 280.182 84.9443C280.751 84.2991 280.71 83.0254 276.051 74.319C271.576 65.9645 269.305 61.7914 267.788 61.9255C264.554 62.2188 260.959 72.1235 264.545 77.8636C265.584 79.5311 266.959 80.411 268.97 81.7014Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M251.758 93.9944C254.817 92.0168 255.747 87.1901 254.121 85.732C252.831 84.5756 250.049 85.6315 248.616 86.5197C245.892 88.1957 243.655 91.9162 244.878 93.8016C245.943 95.444 249.504 95.4608 251.766 93.9944H251.758Z"
        fill="#841B24"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M360.35 146.518C367.849 151.847 383.871 142.739 383.561 137.074C383.352 133.177 375.349 129.926 369.006 129.993C367.322 130.01 359.051 130.094 356.805 135.499C355.238 139.286 357.199 144.28 360.35 146.518Z"
        fill="#841B24"
      />
    </g>
    <path
      d="M81.0061 161.836C82.0033 160.52 91.1622 165.917 93.0225 166.989C114.483 179.341 167.341 194.181 218.918 196.888C275.623 199.863 331.859 190.771 358.129 181.134C360.785 180.162 373.439 175.403 374.142 176.852C374.939 178.511 359.688 187.36 355.112 190.017C313.759 214.007 270.503 239.523 214.913 234.077C172.344 229.912 139.764 210.002 127.932 202.603C113.72 193.72 100.933 182.668 97.6062 179.71C94.6398 177.078 79.6403 163.646 81.0145 161.828L81.0061 161.836Z"
      fill="#101D2A"
    />
    <path
      d="M364.565 171.137C365.051 169.989 371.143 170.157 375.006 173.567C381.609 179.4 378.533 191.936 377.436 192.019C376.614 192.086 377.335 185.207 372.282 178.57C368.528 173.635 364.112 172.168 364.556 171.129L364.565 171.137Z"
      fill="#101D2A"
    />
    <path
      d="M75.5678 172.218C74.4198 171.732 74.5874 165.64 77.9979 161.777C83.8301 155.174 96.366 158.249 96.4498 159.347C96.5169 160.168 89.6372 159.448 83.0005 164.501C78.0649 168.255 76.5985 172.671 75.5594 172.227L75.5678 172.218Z"
      fill="#101D2A"
    />
    <path
      d="M85.2296 163.252C105.416 177.137 158.225 209.482 233.013 211.108C299.706 212.558 349.18 188.818 370.782 176.777C340.213 187.042 289.492 199.904 226.142 196.804C163.412 193.737 114.575 176.316 85.2212 163.252H85.2296Z"
      fill="white"
    />
    <path
      d="M85.2966 163.294C119.184 185.709 157.889 201.262 198.112 207.304C200.852 207.656 204.69 208.385 207.405 208.628C209.081 208.795 217.586 209.65 219.086 209.801C222.488 210.002 227.39 210.203 230.809 210.404C254.222 211.267 277.944 208.98 300.754 203.659C325.164 197.952 348.702 188.6 370.581 176.391L370.933 177.187C278.548 209.03 174.104 204.245 85.3049 163.294H85.2966ZM85.1625 163.219C163.411 197.181 252.378 205.569 335.487 186.195C347.353 183.48 359.076 180.154 370.648 176.366L371 177.162C317.345 206.709 253.71 218.264 193.243 207.966C154.613 201.044 117.198 185.986 85.1709 163.227L85.1625 163.219Z"
      fill="black"
    />
    <path
      d="M155.191 216.831C167.936 223.3 191.768 233.322 223.292 234.571C255.629 235.853 280.718 227.297 293.966 221.7C282.821 220.954 271.567 220.284 260.205 219.697C224.231 217.845 189.204 216.94 155.199 216.84L155.191 216.831Z"
      fill="#C44141"
    />
    <path
      d="M155.266 216.848C198.153 237.202 249.948 240.738 293.79 221.289L293.933 222.136C248.029 219.622 201.229 217.988 155.266 216.848ZM155.116 216.815C201.346 215.834 247.887 217.787 293.991 221.256L295.877 221.39L294.134 222.102C271.953 231.085 247.954 235.694 224.013 235.208C200.072 234.437 176.174 228.286 155.116 216.806V216.815Z"
      fill="black"
    />
    <path
      d="M360.66 123.264C392.542 123.264 418.387 97.419 418.387 65.5371C418.387 33.6552 392.542 7.80981 360.66 7.80981C328.778 7.80981 302.932 33.6552 302.932 65.5371C302.932 97.419 328.778 123.264 360.66 123.264Z"
      fill="url(#paint0_radial_2282_2189)"
    />
    <path
      d="M418.479 65.5372C418.722 88.489 404.452 110.888 383.05 119.594C344.923 135.549 302.748 106.488 302.438 65.5372C301.902 42.3675 316.75 20.0525 338.211 11.3377C376.832 -4.67579 418.655 23.9825 418.479 65.5372ZM418.303 65.5372C418.513 34.3314 391.631 8.45509 360.66 8.73162C329.588 8.55565 303.519 34.4655 303.418 65.5372C303.008 101.846 336.409 128.602 371.771 121.396C397.991 116.393 418.596 92.4693 418.303 65.5372Z"
      fill="black"
    />
    <path
      d="M360.66 107.829C384.017 107.829 402.952 88.8944 402.952 65.5371C402.952 42.1799 384.017 23.2451 360.66 23.2451C337.302 23.2451 318.368 42.1799 318.368 65.5371C318.368 88.8944 337.302 107.829 360.66 107.829Z"
      fill="url(#paint1_linear_2282_2189)"
    />
    <path
      d="M360.66 107.921C337.339 108.072 317.262 89.1844 317.63 65.5455C317.823 42.3758 337.54 22.9267 360.66 22.7675C384.156 22.2312 404.1 42.0658 403.899 65.5455C403.974 89.1761 384.064 108.097 360.66 107.921ZM360.66 107.745C383.402 107.896 402.231 88.0616 401.996 65.5455C402.214 43.021 383.184 23.8736 360.66 23.7479C337.867 23.0859 318.443 42.8618 319.113 65.5455C319.164 88.0532 337.834 107.913 360.66 107.745Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M364.732 23.488C386.871 25.2813 403.957 43.9008 403.924 65.537C403.89 86.2766 388.136 104.326 367.062 107.201C384.935 101.695 397.488 85.8996 398.452 68.0593C399.55 47.8141 385.522 28.8091 364.732 23.488Z"
      fill="#E29F27"
    />
    <path
      d="M364.682 23.4797C399.499 25.9433 416.82 66.2494 393.768 92.8463C387.03 100.706 377.402 106.053 367.104 107.519L366.97 106.899C375.969 104.067 383.972 98.335 389.561 90.8017C408.222 65.6293 394.002 31.6666 364.69 23.4797H364.682ZM364.782 23.4965C394.882 30.6359 409.588 66.1907 390.349 91.3967C384.6 99.0892 376.304 104.779 367.145 107.511L367.011 106.891C377.193 105.617 386.854 100.472 393.516 92.6452C415.806 66.3583 398.402 27.1584 364.774 23.5049L364.782 23.4965Z"
      fill="black"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.22">
      <path
        d="M362.662 67.322L369.651 46.859C370.648 43.9345 373.397 41.9736 376.48 41.9736H376.573C381.55 41.9736 385.036 46.9009 383.377 51.5935L369.475 90.9945C368.453 93.8771 365.729 95.8128 362.671 95.8128H358.581C355.523 95.8128 352.791 93.8855 351.777 90.9945L337.875 51.5935C336.216 46.9009 339.702 41.9736 344.68 41.9736H344.772C347.856 41.9736 350.604 43.9345 351.601 46.859L358.59 67.322C359.252 69.2577 361.992 69.2577 362.654 67.322H362.662Z"
        fill="#8B6543"
      />
    </g>
    <path
      d="M360.852 65.512L367.841 45.0489C368.838 42.1244 371.587 40.1636 374.67 40.1636H374.762C379.74 40.1636 383.226 45.0908 381.567 49.7834L367.665 89.1844C366.643 92.067 363.919 94.0027 360.861 94.0027H356.771C353.713 94.0027 350.981 92.0754 349.967 89.1844L336.065 49.7834C334.406 45.0908 337.892 40.1636 342.87 40.1636H342.962C346.045 40.1636 348.794 42.1244 349.791 45.0489L356.78 65.512C357.442 67.4477 360.182 67.4477 360.844 65.512H360.852Z"
      fill="url(#paint2_radial_2282_2189)"
    />
    <path
      d="M360.802 65.4952C362.109 61.5903 365.478 51.518 366.684 47.722C367.271 45.9623 367.916 43.3059 369.366 42.0155C372.45 38.7223 378.332 39.1329 380.93 42.8451C382.639 45.0573 382.824 48.1326 381.726 50.6633C381.399 51.5599 380.494 54.1324 380.151 55.071C376.623 64.7746 372.944 74.6542 369.517 84.4081C368.846 86.1091 368.168 88.6481 367.372 90.2906C366.123 92.662 363.517 94.2374 360.827 94.2374C359.285 94.2122 357.676 94.3212 356.126 94.2541C352.808 94.0781 350.051 91.3967 349.255 88.2711L348.191 85.3382C344.093 73.9503 339.56 61.4395 335.604 50.0767C333.929 45.8115 336.794 40.5574 341.37 39.8368C346.297 38.9485 349.783 42.2501 350.772 46.8422L354.618 58.7161C355.473 61.1462 356.126 63.7187 357.157 66.0567C358.053 67.3974 360.333 67.0622 360.802 65.5036V65.4952ZM360.903 65.5287C360.425 67.1795 358.003 67.565 357.031 66.1404C355.925 63.7858 355.313 61.2216 354.35 58.7999L350.135 47.0516C349.087 42.9289 346.037 39.8954 341.562 40.7921C337.624 41.5211 335.135 45.9874 336.61 49.7164C340.641 61.0373 345.015 73.6402 348.945 85.07L349.959 88.0197C350.688 90.9944 353.059 93.4245 356.185 93.6843C360.383 93.7932 364.682 94.4468 367.07 90.1146C367.841 88.5727 368.587 85.9499 369.24 84.2991C373.045 73.5648 376.665 62.5875 380.402 51.8364L380.913 50.3616C381.885 48.0907 381.726 45.3757 380.234 43.3646C377.243 38.9988 369.994 39.9541 368.193 44.94L367.137 47.8645C365.788 51.5599 362.26 61.6741 360.919 65.5287H360.903Z"
      fill="black"
    />
    <mask
      id="mask0_2282_2189"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="335"
      y="40"
      width="47"
      height="55"
    >
      <path
        d="M360.852 65.512L367.841 45.0489C368.838 42.1244 371.587 40.1636 374.67 40.1636H374.762C379.74 40.1636 383.226 45.0908 381.567 49.7834L367.665 89.1844C366.643 92.067 363.919 94.0027 360.861 94.0027H356.771C353.713 94.0027 350.981 92.0754 349.967 89.1844L336.065 49.7834C334.406 45.0908 337.892 40.1636 342.87 40.1636H342.962C346.045 40.1636 348.794 42.1244 349.791 45.0489L356.78 65.512C357.442 67.4477 360.182 67.4477 360.844 65.512H360.852Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2282_2189)">
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
        <path d="M374.613 15.2528L310.483 79.3823L318.66 87.5592L382.79 23.4297L374.613 15.2528Z" fill="#E3C26C" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
        <path d="M389.218 30.0678L325.089 94.1973L333.266 102.374L397.395 38.2447L389.218 30.0678Z" fill="#E3C26C" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
        <path d="M395.712 45.4782L331.583 109.608L335.671 113.696L399.8 49.5666L395.712 45.4782Z" fill="#E3C26C" />
      </g>
    </g>
    <g style={{ mixBlendMode: 'color-dodge' }} opacity="0.74">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M327.669 17.5806L413.753 88.9918C412.555 91.2878 411.206 93.6844 409.681 96.1648C407.803 99.215 405.91 101.989 404.058 104.494C374.235 79.8831 344.403 55.2722 314.58 30.6612C315.686 28.9433 317.069 27.0579 318.795 25.139C321.879 21.7033 325.046 19.2649 327.661 17.5806H327.669Z"
        fill="#E29F27"
      />
    </g>
    <g style={{ mixBlendMode: 'color-dodge' }} opacity="0.74">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M308.337 41.563C337.146 64.9338 365.955 88.2962 394.765 111.667C392.192 113.645 388.773 115.907 384.491 117.868C378.961 120.407 373.916 121.563 370.154 122.133C347.805 104.385 325.457 86.637 303.108 68.889C302.781 65.3528 302.706 60.4004 303.837 54.6436C304.927 49.0879 306.77 44.6635 308.345 41.563H308.337Z"
        fill="#E29F27"
      />
    </g>
    <path
      d="M105.022 118.538C136.381 118.538 161.803 93.1169 161.803 61.7579C161.803 30.399 136.381 4.97754 105.022 4.97754C73.6633 4.97754 48.2418 30.399 48.2418 61.7579C48.2418 93.1169 73.6633 118.538 105.022 118.538Z"
      fill="url(#paint3_radial_2282_2189)"
    />
    <path
      d="M161.895 61.7579C162.138 84.3326 148.094 106.363 127.052 114.927C89.5535 130.622 48.0659 102.039 47.7642 61.7579C47.2363 38.9653 61.842 17.019 82.9419 8.44665C120.935 -7.30706 162.062 20.882 161.895 61.7579ZM161.719 61.7579C161.928 31.0717 135.491 5.61433 105.022 5.88248C74.4617 5.70651 48.8284 31.1974 48.7195 61.7579C48.3173 97.4719 81.1738 123.784 115.949 116.695C141.733 111.776 162.004 88.2459 161.71 61.7579H161.719Z"
      fill="black"
    />
    <path
      d="M105.022 103.354C127.995 103.354 146.619 84.731 146.619 61.7579C146.619 38.7848 127.995 20.1614 105.022 20.1614C82.0492 20.1614 63.4258 38.7848 63.4258 61.7579C63.4258 84.731 82.0492 103.354 105.022 103.354Z"
      fill="url(#paint4_linear_2282_2189)"
    />
    <path
      d="M105.022 103.438C82.0788 103.589 62.3364 85.0114 62.6968 61.7579C62.8811 38.9653 82.28 19.843 105.022 19.6837C135.047 19.181 156.079 50.3532 144.214 77.9892C137.812 93.4161 121.597 103.614 105.022 103.438ZM105.022 103.262C127.388 103.405 145.915 83.8969 145.68 61.7579C145.898 39.6021 127.178 20.7731 105.022 20.6474C82.5984 20.0022 63.5012 39.4429 64.1632 61.7579C64.2135 83.8969 82.5816 103.43 105.022 103.262Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.028 20.396C130.806 22.1557 147.608 40.4652 147.574 61.7579C147.541 82.1623 132.047 99.9103 111.315 102.734C128.896 97.3126 141.239 81.7852 142.194 64.2299C143.275 44.3115 129.474 25.6249 109.028 20.396Z"
      fill="#E29F27"
    />
    <path
      d="M108.977 20.3877C143.217 22.8094 160.261 62.4535 137.585 88.6147C130.957 96.3407 121.488 101.603 111.357 103.044L111.223 102.433C120.072 99.6507 127.949 94.0112 133.446 86.6036C151.797 61.8417 137.812 28.4489 108.977 20.3877ZM109.078 20.4045C138.683 27.4266 153.146 62.3948 134.225 87.1901C128.569 94.757 120.416 100.355 111.408 103.036L111.273 102.424C121.287 101.167 130.79 96.1061 137.342 88.4136C159.264 62.554 142.152 23.9993 109.078 20.4128V20.4045Z"
      fill="black"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.22">
      <path
        d="M106.992 63.5092L113.863 43.3814C114.843 40.5071 117.541 38.5798 120.583 38.5798H120.675C125.578 38.5798 129.005 43.4233 127.371 48.0404L113.695 86.7879C112.69 89.6286 110.008 91.5224 107 91.5224H102.978C99.9694 91.5224 97.2879 89.6202 96.2824 86.7879L82.6068 48.0404C80.9728 43.4233 84.4 38.5798 89.3021 38.5798H89.3943C92.4277 38.5798 95.1343 40.5155 96.1148 43.3814L102.986 63.5092C103.64 65.4114 106.33 65.4114 106.983 63.5092H106.992Z"
        fill="#8B6543"
      />
    </g>
    <path
      d="M105.215 61.7243L112.086 41.5965C113.067 38.7222 115.765 36.7949 118.807 36.7949H118.899C123.801 36.7949 127.228 41.6384 125.594 46.2555L111.919 85.003C110.913 87.8437 108.232 89.7374 105.223 89.7374H101.201C98.1929 89.7374 95.5114 87.8353 94.5059 85.003L80.8303 46.2555C79.1963 41.6384 82.6235 36.7949 87.5256 36.7949H87.6178C90.6512 36.7949 93.3579 38.7306 94.3383 41.5965L101.21 61.7243C101.863 63.6265 104.553 63.6265 105.207 61.7243H105.215Z"
      fill="url(#paint5_radial_2282_2189)"
    />
    <path
      d="M105.165 61.7076C106.455 57.8698 109.765 47.9567 110.955 44.2277C111.533 42.5015 112.17 39.8871 113.595 38.6134C116.628 35.3788 122.41 35.7811 124.966 39.4262C126.65 41.6049 126.826 44.63 125.754 47.1187C125.435 47.9986 124.538 50.5292 124.203 51.451C120.734 60.9954 117.114 70.7158 113.745 80.3021C113.084 81.978 112.422 84.4667 111.634 86.0924C110.402 88.4219 107.838 89.9721 105.198 89.9721C103.682 89.947 102.098 90.0559 100.573 89.9889C97.3047 89.8129 94.598 87.1817 93.8187 84.1064L92.7713 81.2238C88.7407 70.0202 84.2827 57.7189 80.3946 46.5405C78.7438 42.3423 81.5677 37.1805 86.0592 36.4682C90.9026 35.5967 94.3383 38.8396 95.3019 43.3562L99.0895 55.0375C99.9359 57.4257 100.573 59.9647 101.587 62.2607C102.475 63.5847 104.712 63.2495 105.173 61.716L105.165 61.7076ZM105.257 61.7412C104.788 63.3668 102.399 63.7439 101.444 62.3445C100.355 60.0317 99.7515 57.5094 98.8046 55.1213L94.6567 43.5657C93.626 39.51 90.6261 36.5268 86.2268 37.4067C82.3554 38.119 79.9085 42.5099 81.3582 46.1886C85.3218 57.3251 89.6205 69.7186 93.4835 80.964L94.4807 83.8634C95.193 86.7963 97.5309 89.1761 100.606 89.4359C104.737 89.5448 108.969 90.1816 111.307 85.9248C112.061 84.4081 112.799 81.8271 113.435 80.2015C117.181 69.6432 120.734 58.8418 124.413 48.2751C124.538 47.8645 125.041 46.5154 125.167 46.1048C125.871 44.0518 125.527 41.7055 124.245 39.9457C121.304 35.6554 114.173 36.5939 112.405 41.496L111.366 44.3786C110.033 48.0153 106.573 57.962 105.249 61.7495L105.257 61.7412Z"
      fill="black"
    />
    <mask
      id="mask1_2282_2189"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="80"
      y="36"
      width="47"
      height="54"
    >
      <path
        d="M105.215 61.7243L112.086 41.5965C113.067 38.7222 115.765 36.7949 118.807 36.7949H118.899C123.801 36.7949 127.228 41.6384 125.594 46.2555L111.919 85.003C110.913 87.8437 108.232 89.7374 105.223 89.7374H101.201C98.1929 89.7374 95.5114 87.8353 94.5059 85.003L80.8303 46.2555C79.1963 41.6384 82.6235 36.7949 87.5256 36.7949H87.6178C90.6512 36.7949 93.3579 38.7306 94.3383 41.5965L101.21 61.7243C101.863 63.6265 104.553 63.6265 105.207 61.7243H105.215Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_2282_2189)">
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
        <path d="M118.745 12.299L55.6698 75.3738L63.7104 83.4144L126.785 20.3396L118.745 12.299Z" fill="#E3C26C" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
        <path d="M133.113 26.8566L70.0378 89.9314L78.0785 97.972L141.153 34.8972L133.113 26.8566Z" fill="#E3C26C" />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.3">
        <path d="M139.495 42.0278L76.4205 105.103L80.4438 109.126L143.519 46.051L139.495 42.0278Z" fill="#E3C26C" />
      </g>
    </g>
    <g style={{ mixBlendMode: 'color-dodge' }} opacity="0.74">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.5763 14.5806C100.799 37.9933 129.022 61.406 157.253 84.8187C156.071 87.0728 154.747 89.4358 153.247 91.8743C151.404 94.8742 149.543 97.606 147.716 100.07C118.379 75.8608 89.0423 51.6604 59.7052 27.4517C60.7946 25.759 62.1521 23.9071 63.8531 22.0217C66.8865 18.6447 70.0038 16.2481 72.5763 14.5889V14.5806Z"
        fill="#E29F27"
      />
    </g>
    <g style={{ mixBlendMode: 'color-dodge' }} opacity="0.74">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.5629 38.1692C81.8944 61.1545 110.234 84.1315 138.566 107.117C136.035 109.069 132.675 111.281 128.468 113.217C123.03 115.714 118.069 116.854 114.366 117.407C92.3857 99.9522 70.3976 82.4975 48.4178 65.0427C48.0994 61.5651 48.0239 56.6966 49.1301 51.0319C50.2027 45.5684 52.0127 41.2194 53.5629 38.1692Z"
        fill="#E29F27"
      />
    </g>
    <path
      d="M411.457 63.4925C408.675 49.3644 407.711 47.3533 406.421 45.669C404.728 44.3785 402.717 43.4149 388.597 40.6328C402.725 37.8508 404.737 36.8871 406.421 35.5967C407.711 33.904 408.675 31.8929 411.457 17.7732C414.239 31.9013 415.203 33.9124 416.493 35.5967C418.186 36.8871 420.197 37.8508 434.317 40.6328C420.189 43.4149 418.178 44.3785 416.493 45.669C415.203 47.3617 414.239 49.3728 411.457 63.4925Z"
      fill="#F5F050"
    />
    <path
      d="M411.457 63.5428C410.803 61.0205 409.898 56.9983 409.253 54.4341C408.457 51.5431 407.887 48.3337 406.086 45.912C404.125 44.4623 400.362 43.6579 397.597 43.0127C394.58 42.3423 391.547 41.6803 388.505 41.0686C388.254 41.0267 388.094 40.7837 388.145 40.5323C388.178 40.3479 388.329 40.2055 388.505 40.1803C391.555 39.6189 394.614 39.0575 397.639 38.4039C400.521 37.7335 403.999 37.1385 406.27 35.471C408.122 33.0074 408.599 29.7728 409.345 26.8316C410.049 23.8065 410.594 20.7564 411.164 17.6978C411.197 17.5386 411.348 17.4296 411.516 17.4548C411.641 17.4799 411.733 17.5721 411.759 17.6978C412.404 20.7312 413.057 23.773 413.795 26.7729C414.591 29.6388 415.127 32.8649 416.912 35.2699C418.739 36.6358 422.627 37.524 425.342 38.1441C428.359 38.8228 431.392 39.4597 434.434 40.063C434.736 40.1217 434.937 40.415 434.878 40.725C434.836 40.9597 434.652 41.1272 434.434 41.1691C431.392 41.7557 428.35 42.3842 425.325 43.0462C422.568 43.6747 418.789 44.4791 416.837 45.9036C415.027 48.317 414.457 51.5348 413.652 54.4173C413.007 56.9731 412.094 60.987 411.465 63.526L411.457 63.5428ZM411.457 63.4338C411.918 60.3836 412.471 57.3083 413.108 54.3084C413.853 51.2666 414.256 48.0404 416.15 45.4009C418.73 43.3646 422.032 42.887 425.082 42.0322C428.107 41.2865 431.166 40.6915 434.208 40.0714V41.1775C431.157 40.5742 428.107 39.9876 425.074 39.2586C422.024 38.4374 418.806 37.9514 416.175 35.9905C414.147 33.3677 413.753 30.0577 412.965 26.974C412.295 23.9322 411.725 20.8737 411.155 17.8235H411.742C411.105 20.8653 410.418 23.8987 409.714 26.9238C408.901 29.9237 408.423 33.175 406.53 35.7391C403.974 37.6329 400.756 38.1525 397.773 38.9988C394.756 39.753 391.714 40.4234 388.673 41.0686V40.1803C391.723 40.7753 394.781 41.3451 397.806 42.0825C400.857 42.9205 404.158 43.3897 406.739 45.4092C408.641 48.0404 409.044 51.2666 409.798 54.3084C410.443 57.3083 411.004 60.3753 411.44 63.4338H411.457Z"
      fill="black"
    />
    <path
      d="M54.669 116.494C51.8869 102.366 50.9233 100.355 49.6328 98.6702C47.9401 97.3798 45.929 96.4161 31.8093 93.6341C45.9374 90.852 47.9485 89.8884 49.6328 88.5979C50.9233 86.9052 51.8869 84.8941 54.669 70.7744C57.451 84.9025 58.4147 86.9136 59.7051 88.5979C61.3978 89.8884 63.4089 90.852 77.5286 93.6341C63.4005 96.4161 61.3894 97.3798 59.7051 98.6702C58.4147 100.363 57.451 102.374 54.669 116.494Z"
      fill="#F5F050"
    />
    <path
      d="M54.6689 116.544C54.0153 114.022 53.1103 110 52.4651 107.435C51.669 104.544 51.0992 101.335 49.2976 98.9132C47.3368 97.4636 43.5743 96.6591 40.809 96.0139C37.7924 95.3435 34.7589 94.6815 31.7171 94.0698C31.4657 94.0279 31.3065 93.7849 31.3568 93.5335C31.3903 93.3492 31.5411 93.2067 31.7171 93.1816C34.7673 92.6201 37.8259 92.0587 40.8509 91.4051C43.7335 90.7347 47.2111 90.1398 49.4819 88.4722C51.3338 86.0086 51.8115 82.7741 52.5573 79.8328C53.2612 76.8078 53.8058 73.7576 54.3756 70.699C54.4092 70.5398 54.56 70.4309 54.7276 70.456C54.8533 70.4811 54.9455 70.5733 54.9706 70.699C55.6158 73.7324 56.2694 76.7742 57.0069 79.7741C57.8029 82.64 58.3392 85.8661 60.1241 88.2711C61.9508 89.637 65.839 90.5252 68.554 91.1453C71.5707 91.8241 74.6041 92.4609 77.6459 93.0642C77.9476 93.1229 78.1487 93.4162 78.09 93.7262C78.0481 93.9609 77.8638 94.1285 77.6459 94.1704C74.6041 94.7569 71.5623 95.3854 68.5372 96.0474C65.7803 96.6759 62.0011 97.4803 60.0487 98.9049C58.2387 101.318 57.6688 104.536 56.8644 107.419C56.2192 109.974 55.3058 113.988 54.6773 116.527L54.6689 116.544ZM54.6689 116.435C55.1298 113.385 55.6829 110.31 56.3197 107.31C57.0655 104.268 57.4677 101.042 59.3615 98.4021C61.9425 96.3658 65.244 95.8882 68.2942 95.0335C71.3193 94.2877 74.3778 93.6927 77.4196 93.0726V94.1787C74.3695 93.5754 71.3193 92.9888 68.2858 92.2598C65.2357 91.4386 62.0179 90.9526 59.3867 88.9917C57.3588 86.3689 56.965 83.059 56.1773 79.9753C55.5069 76.9335 54.9371 73.8749 54.3673 70.8247H54.9538C54.317 73.8665 53.6299 76.8999 52.926 79.925C52.1131 82.9249 51.6355 86.1762 49.7417 88.7404C47.1859 90.6342 43.9681 91.1537 40.985 92C37.9683 92.7542 34.9265 93.4246 31.8847 94.0698V93.1816C34.9349 93.7765 37.9935 94.3463 41.0185 95.0837C44.0687 95.9217 47.3703 96.391 49.9512 98.4105C51.8534 101.042 52.2556 104.268 53.0098 107.31C53.655 110.31 54.2164 113.376 54.6522 116.435H54.6689Z"
      fill="black"
    />
    <defs>
      <radialGradient
        id="paint0_radial_2282_2189"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(360.66 65.5371) scale(57.7273)"
      >
        <stop offset="0.06" stopColor="#FFDF47" />
        <stop offset="0.5" stopColor="#FFEB51" />
        <stop offset="1" stopColor="#FFFF62" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_2282_2189"
        x1="318.376"
        y1="65.5371"
        x2="402.952"
        y2="65.5371"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.06" stopColor="#FFC944" />
        <stop offset="0.65" stopColor="#FFD53A" />
        <stop offset="1" stopColor="#FFDF34" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_2282_2189"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(358.824 67.079) scale(25.1138 25.1138)"
      >
        <stop offset="0.06" stopColor="#FFDF47" />
        <stop offset="0.5" stopColor="#FFEB51" />
        <stop offset="1" stopColor="#FFFF62" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_2282_2189"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(105.022 61.7579) scale(56.7804)"
      >
        <stop offset="0.06" stopColor="#FFDF47" />
        <stop offset="0.5" stopColor="#FFEB51" />
        <stop offset="1" stopColor="#FFFF62" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_2282_2189"
        x1="63.4342"
        y1="61.7579"
        x2="146.619"
        y2="61.7579"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.06" stopColor="#FFC944" />
        <stop offset="0.65" stopColor="#FFD53A" />
        <stop offset="1" stopColor="#FFDF34" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_2282_2189"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(103.212 63.2662) scale(24.7032 24.7032)"
      >
        <stop offset="0.06" stopColor="#FFDF47" />
        <stop offset="0.5" stopColor="#FFEB51" />
        <stop offset="1" stopColor="#FFFF62" />
      </radialGradient>
    </defs>
  </svg>
))`
  margin: 45px 0 60px;
  display: flex;
`;

export default UnlockFrog;

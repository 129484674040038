import styled from 'styled-components';
import { shakeAnimation } from './shakeAnimation';

const Bronze = styled(({ shaking, ...props }) => (
  <svg {...props} width="3252" height="2902" viewBox="0 0 3252 2902" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="paper_behind_above">
      <path
        d="M1758.15 2902.04C2583.18 2902.04 3252 2774.3 3252 2616.71C3252 2459.13 2583.18 2331.38 1758.15 2331.38C933.122 2331.38 264.305 2459.13 264.305 2616.71C264.305 2774.3 933.122 2902.04 1758.15 2902.04Z"
        fill="#E4D9B0"
      />
      <path
        d="M2223.75 472.101L2275.7 491.275L2349.11 465.246L2393.41 471.698L2385.98 577.47L2312.7 677.374L2241.62 650.18L2181.37 667.831L2087.63 618.059L2177.52 554.308L2223.75 472.101Z"
        fill="#F0E7EB"
      />
      <path
        d="M2223.75 472.011C2223.48 472.145 2276.96 490.961 2275.57 490.827C2275.84 491.006 2348.94 464.305 2349.25 464.574L2393.55 470.936C2393.95 470.981 2394.22 471.339 2394.17 471.742C2394.08 471.966 2386.51 577.515 2386.34 577.694C2367.61 602.423 2331.33 653.406 2312.57 677.732C2312.88 677.553 2239.87 650.628 2241.8 650.807L2181.6 668.593C2180.88 669.086 2087.85 618.731 2087.23 618.775C2086.82 618.551 2086.64 618.059 2086.87 617.655C2116.43 596.331 2147.87 574.513 2177.03 554.04L2223.7 472.011H2223.75ZM2223.79 472.19L2177.97 554.532C2148.14 576.036 2118.18 597.495 2088.12 618.731L2088.03 617.297C2087.85 617.118 2183.66 667.921 2181.15 667.07L2241.44 649.508C2241.75 649.374 2312.52 676.836 2312.88 676.926C2329 653.674 2368.24 601.707 2385.66 577.156C2385.62 578.366 2392.65 471.339 2392.74 471.608L2393.37 472.414L2349.07 465.873C2350.77 465.336 2275.43 491.857 2275.88 491.633C2275.61 491.723 2224.1 472.235 2223.84 472.145L2223.79 472.19Z"
        fill="black"
      />
      <path
        d="M2177.52 554.308L2232.21 577.56L2181.37 667.831L2241.61 650.18L2296.8 572.363L2385.97 577.47L2313.14 548.888L2349.11 465.246L2285.51 561.835L2241.3 571.377L2275.7 491.275L2223.74 472.101L2177.52 554.308Z"
        fill="#D2C2C9"
      />
    </g>
    <g id="paper_behind_above">
      <path
        d="M2177.61 554.264L2232.39 577.156C2232.61 577.246 2232.7 577.56 2232.61 577.784L2182 668.19L2181.19 667.204C2181.19 667.204 2242.87 648.971 2241.08 649.777L2296.44 572.094C2296.75 571.512 2385.57 577.336 2386.02 577.156L2385.89 577.739L2313.01 549.291C2312.79 549.201 2312.65 548.933 2312.74 548.664L2348.49 464.933L2349.78 465.65C2349.16 466.098 2286.59 562.596 2285.73 562.596L2241.53 572.184C2240.9 572.363 2240.32 571.646 2240.59 571.019L2275.16 490.962L2275.57 491.858L2223.7 472.504C2225.81 470.622 2177.21 554.622 2177.7 554.219L2177.61 554.264ZM2177.43 554.309C2178.1 554.085 2222.85 471.115 2223.92 471.653L2275.93 490.603C2276.28 490.738 2276.46 491.141 2276.33 491.499L2242.06 571.691L2241.12 570.526L2285.33 560.984C2283.14 563.134 2349.02 464.395 2348.49 464.798C2348.93 464.082 2350.19 464.664 2349.78 465.515L2313.55 549.022L2313.28 548.395L2386.06 577.156C2386.38 577.246 2386.29 577.784 2385.93 577.739C2386.11 577.515 2295.27 573.08 2297.11 572.587C2296.62 572.721 2242.38 650.897 2241.75 650.852L2181.51 668.503C2180.97 668.683 2180.39 668.011 2180.7 667.518C2181.1 667.876 2231.9 575.275 2231.99 577.963L2177.39 554.309H2177.43Z"
        fill="black"
      />
      <path
        d="M2486.31 1498.29L2519 1576.24L2624.48 1631.12L2663.36 1687.3L2537.64 1789.08L2349.88 1811.26L2306.16 1703.65L2223.75 1654.77L2181.69 1498.29L2346.43 1532.24L2486.31 1498.29Z"
        fill="#F0E7EB"
      />
      <path
        d="M2486.35 1498.2C2485.95 1498.06 2520.62 1577.31 2519.32 1575.66C2519.41 1576.15 2625.34 1629.95 2625.34 1630.58L2664.35 1686.67C2664.71 1687.16 2664.57 1687.83 2664.12 1688.19C2663.77 1688.33 2538.13 1789.8 2537.73 1789.75C2490.65 1794.59 2395.96 1807.18 2349.29 1811.53C2349.83 1811.66 2303.88 1702.13 2305.67 1704.5L2223.16 1655.8C2221.86 1655.58 2181.19 1499.27 2180.52 1498.6C2180.34 1497.93 2180.75 1497.21 2181.42 1497.03C2235.93 1507.78 2293.31 1520.19 2346.25 1531.39L2486.35 1498.15V1498.2ZM2486.22 1498.42L2346.61 1533.1C2291.51 1522.16 2236.42 1510.96 2181.42 1499.54L2182.9 1497.97C2182.9 1497.57 2225.99 1657.37 2224.33 1653.74L2306.65 1702.75C2307.15 1702.98 2350.19 1810.54 2350.41 1811.03C2393.14 1804.81 2491.95 1794.5 2537.5 1788.41C2536.11 1789.62 2662.82 1686.04 2662.65 1686.4L2662.42 1687.92L2623.63 1631.66C2626.01 1633.04 2517.97 1576.46 2518.69 1576.78C2518.33 1576.55 2486.4 1498.78 2486.26 1498.38L2486.22 1498.42Z"
        fill="black"
      />
      <path
        d="M2346.43 1532.24L2377.42 1617.54L2223.75 1654.77L2306.16 1703.65L2450.47 1684.16L2537.64 1789.08L2493.7 1678.02L2624.48 1631.12L2450.47 1660.64L2393.77 1621.26L2519 1576.24L2486.31 1498.29L2346.43 1532.24Z"
        fill="#D2C2C9"
      />
      <path
        d="M2346.56 1532.33L2378.05 1617.32C2378.18 1617.68 2377.96 1618.08 2377.6 1618.17L2224.02 1655.8L2224.28 1653.88C2250.71 1669.69 2282.2 1687.48 2306.03 1702.57L2450.39 1683.49C2480.04 1717.81 2508.48 1754 2538 1788.77L2537.23 1789.22L2493.07 1678.25C2492.94 1677.89 2493.07 1677.49 2493.47 1677.31C2493.47 1677.31 2624.08 1630 2624.13 1630L2624.71 1632.19C2623.55 1632.01 2450.74 1662.57 2449.8 1661.63L2393.1 1622.25C2392.25 1621.71 2392.43 1620.32 2393.41 1620.01L2518.69 1575.16L2518.06 1576.6L2485.64 1498.56C2490.61 1499.05 2345.58 1532.07 2346.65 1532.24L2346.56 1532.33ZM2346.34 1532.2L2486.13 1497.52C2486.53 1497.44 2486.93 1497.66 2487.07 1498.02L2520.03 1575.83C2520.26 1576.42 2519.99 1577.04 2519.41 1577.27L2394.26 1622.47L2394.58 1620.23L2451.28 1659.66C2446.62 1659.48 2625.38 1630.13 2624.4 1630C2625.02 1629.91 2625.61 1630.31 2625.69 1630.94C2625.78 1631.48 2625.47 1632.01 2624.93 1632.19L2494.01 1678.7L2494.41 1677.75L2538.09 1788.9C2538.26 1789.35 2537.59 1789.75 2537.32 1789.35C2509.33 1754.86 2476.81 1717.76 2450.61 1684.83C2449.89 1684.43 2306.25 1705.31 2305.67 1704.5C2278.12 1688.33 2250.67 1672.02 2223.25 1655.67C2222.49 1655.22 2222.63 1653.92 2223.52 1653.74C2223.52 1654.55 2379.71 1614.81 2376.84 1617.72L2346.34 1532.15V1532.2Z"
        fill="black"
      />
    </g>

    <g id="frog" className={shaking ? 'shaking' : ''}>
      <image x="882" y="413" width="1231" height="2298" xlinkHref="/img/frog/1.bronze.png" />
    </g>

    <g id="paper_in_front">
      <path
        d="M407.314 1154.79L587.014 1038.26L701.842 1227.44L989.125 1320.47L1095.11 1512.64L716.318 1684.9L627.074 1542.15L293.887 1505.17L-12.6359 1207.29L407.314 1154.79Z"
        fill="#F0E7EB"
      />
      <path
        d="M407.255 1154.61C451.856 1125.35 541.598 1066.28 586.35 1037.23C586.95 1036.87 587.727 1037.03 588.089 1037.64C587.169 1037.93 706.487 1229.36 702.43 1225.77C786.056 1252.17 905.121 1291.55 989.638 1319C991.349 1319.04 1094.98 1511.48 1096.02 1512.16C1096.28 1512.68 1096.11 1513.33 1095.55 1513.59L1000.75 1556.38C941.019 1583.52 778.697 1657.74 716.857 1686.02C716.294 1686.29 715.603 1686.08 715.293 1685.56L625.624 1543.11L626.9 1543.91C625.578 1542.53 293.355 1508.67 292.437 1506.72L139.09 1357.83C87.9184 1308.25 36.9558 1258.48 -13.9656 1208.65C-15.0771 1207.66 -14.4317 1205.59 -12.9135 1205.47C91.9792 1192.71 302.386 1167.1 407.214 1154.66L407.255 1154.61ZM407.424 1155.05C302.97 1168.81 92.088 1195.8 -12.3982 1209.16L-11.3461 1205.97C39.9079 1255.46 91.162 1304.94 142.166 1354.67L295.386 1503.66C288.241 1501.93 629.082 1540.82 627.254 1540.42C627.797 1540.47 628.271 1540.79 628.53 1541.22L717.386 1684.18L715.822 1683.72C778.336 1655.43 939.685 1582.54 1000.1 1555.04L1094.68 1511.73L1094.21 1513.16C1094.06 1513.58 985.7 1318.05 988.662 1321.99C903.368 1294.96 785.088 1256.42 701.347 1229.14C700.072 1228.92 586.777 1039.81 585.989 1038.92L587.728 1039.33C542.887 1068.38 452.421 1126.25 407.424 1155.05Z"
        fill="black"
      />
      <path
        d="M407.313 1154.79L524.835 1410.67L701.842 1227.44L558.91 1407.49L792.656 1407.23L989.125 1320.47L1095.11 1512.64L716.318 1684.9L627.074 1542.15L752.462 1426.84L517.963 1431.79L293.887 1505.17L-12.6361 1207.29L407.313 1154.79Z"
        fill="#D2C2C9"
      />
      <path
        d="M407.433 1154.6L525.961 1410.13L523.913 1409.8L700.623 1226.23C701.289 1225.55 702.364 1225.51 703.045 1226.17C703.678 1226.8 703.756 1227.78 703.228 1228.5C655.618 1288.55 608.293 1348.82 560.397 1408.67L558.897 1405.6C558.454 1405.68 794.948 1405.52 792.056 1405.88C792.813 1406.36 989.374 1317.35 990.028 1319.95C989.703 1320.97 1097.48 1512.45 1095.39 1513.29C1093.55 1512.76 716.635 1688.91 715.073 1685.62L625.489 1543.12C625.024 1542.35 625.173 1541.4 625.801 1540.81L751.037 1425.29L752.483 1428.82L518.036 1433.85C520.947 1433.17 294.008 1507.27 294.588 1507.23C293.799 1507.47 292.929 1507.27 292.392 1506.72L139.168 1357.69L-13.7606 1208.41C-14.7275 1207.54 -14.1468 1205.79 -12.8519 1205.69L407.433 1154.6ZM407.194 1154.98L-12.4681 1208.85L-11.5594 1206.13L142.079 1354.72L295.427 1503.61L293.231 1503.11C293.852 1503.01 517.341 1429.59 517.932 1429.68L752.39 1424.78C754.176 1424.64 755.229 1427.16 753.836 1428.31L628.303 1543.49L628.615 1541.17L717.557 1684.08L715.763 1683.55C715.004 1683.61 1097.73 1509.87 1094.51 1513L988.267 1320.99L989.605 1321.42L793.301 1408.57C793.329 1408.93 559.141 1409.27 558.973 1409.42C557.406 1409.49 556.485 1407.5 557.473 1406.34C604.894 1346.16 652.883 1286.34 700.589 1226.37L703.149 1228.64L525.758 1411.54C525.302 1412.02 524.494 1412.04 524.009 1411.59C523.218 1411.24 407.816 1155.47 407.286 1155.01L407.194 1154.98Z"
        fill="black"
      />
    </g>

    <path
      d="M2754.02 376.901L2755.54 512.062L2755.9 540.376L2777 2422.91L2703.27 2426.4L2708.69 2040.5L2730.01 509.016L2731.85 377.932L2754.02 376.901Z"
      fill="#CEC8AC"
    />
    <path
      d="M2754.2 376.722C2762.13 869.207 2767.64 1416.03 2772.21 1911.39C2773.59 2082.25 2776.19 2253.12 2778.21 2424.03H2777.09L2703.36 2427.66C2706.99 1789.04 2721.82 1019.2 2731.54 377.932V377.663H2731.8L2754.15 376.767L2754.2 376.722ZM2753.89 377.036L2731.85 378.2L2732.12 377.887C2723.97 1017.09 2717.29 1792.04 2703.18 2425.06L2776.91 2421.7C2771.89 2258.63 2773.86 2079.74 2770.32 1911.34C2763.74 1415.5 2757.07 869.924 2753.84 376.946L2753.89 377.036Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.41}
    >
      <path
        d="M2755.54 512.062L2755.9 540.376L2736.86 540.779L2731.13 2040.01L2708.69 2040.5L2730.01 509.016L2755.54 512.062Z"
        fill="#7E003A"
      />
      <path
        d="M2755.72 511.928L2756.26 540.376V540.734H2755.9L2736.86 541.272C2738.57 663.172 2736.69 791.524 2737.04 915.619C2736.78 1271.33 2732.3 1684.52 2732.34 2040.01V2041.17H2731.18L2708.74 2041.75H2707.44C2712.86 1554.87 2720.65 995.542 2729.7 509.016V508.613H2730.1L2755.77 511.883L2755.72 511.928ZM2755.41 512.242L2730.01 509.419C2731.18 507.358 2725.98 892.324 2726.38 891.965C2721.32 1239.97 2718.05 1695.85 2708.69 2039.29L2731.13 2038.84L2729.97 2040.05C2732.74 1684.61 2731.4 1271.29 2733.86 915.664C2734.67 790.583 2735.12 665.502 2736.42 540.421H2736.86L2755.9 540.107L2755.54 540.465L2755.41 512.286V512.242Z"
        fill="black"
      />
    </g>
    <path
      d="M2799.84 2460.05L2681.64 2462.65L2681.68 2461.53L2702.55 2011.96L2782.77 2010.17L2799.61 2453.96L2799.84 2459.47V2460.05Z"
      fill="#E8E2DE"
    />
    <path
      d="M2800.02 2460.18C2769.65 2461.26 2710.35 2463.45 2679.98 2464.31L2680.07 2462.56L2684.91 2349.89C2688.4 2274.76 2693.37 2199.72 2696.64 2124.59L2701.21 2011.87V2010.62H2702.55L2782.77 2008.29H2784.7L2784.79 2010.17L2789.45 2122.62C2793.34 2226.15 2797.82 2357.05 2800.06 2460.23L2800.02 2460.18ZM2799.7 2459.87C2794.2 2357.1 2788.6 2226.01 2784.7 2122.71L2780.85 2010.21L2782.82 2012.1L2702.55 2013.31L2703.9 2012.01L2698.03 2124.63C2694.31 2199.72 2692.3 2274.94 2688.85 2350.02L2683.25 2462.69L2681.59 2460.99C2711.02 2460.5 2770.23 2460.09 2799.66 2459.87H2799.7Z"
      fill="black"
    />
    <path
      d="M2849.73 2479L2631.2 2483.84L2655.84 2463.19L2681.68 2461.53L2694.72 2460.72L2713.12 2459.51L2735.12 2458.12L2761.99 2456.38L2799.61 2453.96L2825.46 2452.3L2849.73 2479Z"
      fill="#E8E2DE"
    />
    <path
      d="M2849.92 2479.09C2817.49 2480.34 2773.64 2482.36 2741.52 2482.99C2703.68 2483.52 2666.01 2483.12 2628.29 2485L2630.58 2483.03L2654.81 2461.93C2683.03 2458.8 2712.05 2457.5 2740.58 2455.44C2769.07 2453.64 2797.6 2452.12 2826.13 2451C2834.51 2460.5 2842.26 2470.26 2850 2479.13L2849.92 2479.09ZM2849.51 2478.91C2841 2469.82 2832.4 2461.57 2824.52 2453.15L2825.55 2453.6C2783.22 2457.32 2740.81 2460.32 2698.39 2462.65L2655.93 2464.89L2656.91 2464.48L2631.88 2484.6L2631.2 2482.76C2667.31 2482.9 2703.45 2480.93 2739.42 2479.85C2770.86 2479.09 2817.49 2479.09 2849.51 2478.91Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'color-dodge',
      }}
      opacity={0.8}
    >
      <path
        d="M2849.74 2479L2819.01 2479.67L2812.74 2459.74L2799.84 2459.47L2735.12 2458.12L2713.08 2457.63L2761.99 2456.38L2761.59 2023.56L2782.78 2010.17L2799.62 2453.96L2825.46 2452.3L2849.74 2479Z"
        fill="url(#paint0_linear_2458_14)"
      />
      <path
        d="M2849.92 2479.09L2819.01 2480.34H2818.52L2818.34 2479.9L2811.8 2460.05L2812.7 2460.72L2762.84 2460.14L2712.99 2459.42V2455.93L2761.9 2454.49L2760.07 2456.42C2759.57 2393.88 2760.51 2303.47 2760.92 2239.99C2761.23 2179.38 2760.38 2084.85 2759.98 2023.56V2022.71L2760.74 2022.22C2761.81 2021.5 2783.81 2007.48 2784.39 2007.08L2784.52 2010.12L2789.23 2121.05C2793.48 2224.4 2797.65 2350.56 2800.65 2453.91L2799.57 2452.93L2825.46 2451.58H2825.82L2826.04 2451.81L2849.96 2479.09H2849.92ZM2849.51 2478.91L2824.97 2452.75L2825.55 2452.97L2799.71 2454.94L2798.68 2455.03V2453.96C2793.79 2350.65 2788.37 2224.54 2784.79 2121.18L2781.07 2010.21L2783.76 2011.6L2762.44 2024.82L2763.16 2023.47L2762.66 2131.67C2762.08 2191.97 2763.07 2287.08 2763.65 2348.09C2763.92 2384.79 2764.1 2421.48 2763.92 2458.17H2762.08L2713.13 2459.29V2455.79C2737.99 2456.38 2787.93 2457.94 2812.78 2458.71H2813.5L2813.68 2459.33L2819.64 2479.36L2819.01 2478.91L2849.56 2478.82L2849.51 2478.91Z"
        fill="black"
      />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.41}
    >
      <path
        d="M2729.7 2011.33L2713.12 2459.51L2694.72 2460.72L2694.18 2462.38L2687.64 2482.58L2631.2 2483.84L2655.84 2463.19L2681.68 2461.53L2702.55 2011.96L2729.7 2011.33Z"
        fill="#862D81"
      />
      <path
        d="M2729.83 2011.2C2727.06 2115.63 2723.61 2243.58 2718.5 2347.29C2716.71 2384.79 2715.14 2422.15 2713.84 2459.56V2460.18H2713.17L2694.81 2461.39L2695.48 2460.86L2688.45 2482.76L2688.27 2483.35H2687.69L2631.29 2485.05L2627.71 2485.14C2628.16 2484.91 2653.64 2463.01 2654.94 2461.98C2655.35 2461.62 2655.35 2461.66 2655.79 2461.62L2681.64 2459.78L2680.07 2461.39C2684.51 2348.95 2689.52 2236.54 2695.75 2124.19C2697.85 2086.55 2699.64 2048.88 2702.06 2011.24H2702.65L2729.97 2011.11L2729.83 2011.2ZM2729.52 2011.51L2702.56 2012.54L2703.14 2011.96C2700.58 2086.91 2697.63 2161.91 2694.36 2236.86C2691.04 2312.3 2687.33 2387.74 2683.25 2463.14L2681.77 2463.23L2655.93 2464.71L2656.78 2464.35L2632.01 2484.78L2631.16 2482.54L2687.6 2481.73L2686.83 2482.32L2694 2460.45L2694.18 2459.96H2694.72L2713.08 2458.8C2712.45 2425.91 2715.77 2383.58 2716.04 2347.65C2718.59 2244.11 2724.59 2115.49 2729.56 2011.47L2729.52 2011.51Z"
        fill="black"
      />
    </g>
    <path
      d="M2771.8 121.811L2756.26 151.2L2759.22 518.782L2721.19 519.634L2725.94 149.945L2353.28 43.8592L2352.39 2.59851L2487.61 2.10571L2771.8 121.811Z"
      fill="#E8E2DE"
    />
    <path
      d="M2772.03 121.721L2756.8 151.468C2757.51 180.678 2757.92 212.889 2758.28 243.084C2759.08 328.473 2760.96 433.842 2761.01 518.782V520.529H2759.26L2721.23 521.246H2719.53V519.589C2720.52 455.122 2723.47 304.505 2723.92 242.322L2724.82 149.9L2725.62 150.976C2631.74 124.857 2444.7 72.2174 2350.95 45.6511V43.9039L2350.1 2.6432V0.268806H2352.43L2487.61 7.15442e-06C2488.06 -0.85119 2771.44 122.035 2772.03 121.721ZM2771.62 121.856C2771.89 122.528 2484.02 2.688 2487.65 4.21119L2352.43 4.92799L2354.76 2.5536L2355.7 43.8143L2354 41.5743C2446.89 68.1406 2633.58 121.856 2726.3 148.825L2727.1 149.049V149.9L2725.58 242.322C2724.46 303.877 2723.47 456.107 2722.8 519.633L2721.14 517.976L2759.17 516.99L2757.42 518.782C2757.11 488.139 2756.57 457.496 2756.57 426.898C2756.35 381.023 2756.04 288.914 2755.81 243.084C2755.68 212.396 2755.54 181.619 2755.81 150.931L2771.62 121.856Z"
      fill="black"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.41}
    >
      <path
        d="M2732.34 121.542L2751.6 144.211L2737.67 519.275L2721.19 519.634L2725.93 149.945L2353.28 43.8592L2352.38 2.59851L2487.6 2.10571V32.032L2732.34 121.542Z"
        fill="#862D81"
      />
      <path
        d="M2732.43 121.363C2732.43 121.363 2752 143.897 2752.14 144.032C2751.29 175.392 2750.48 206.707 2749.36 238.022C2746.27 325.113 2743.31 432.722 2739.42 519.365L2739.37 520.978H2737.76L2721.28 521.291H2719.57V519.634C2721.23 412.428 2724.41 255.762 2724.86 149.945C2637.92 128.307 2443.76 71.1423 2352.7 46.1888L2351.04 45.696V43.9488L2350.14 2.68811V0.268921H2352.47L2487.7 0.0449219H2489.76V2.10572L2489.62 32.032L2488.37 30.24C2560.35 57.2543 2661.84 94.3934 2732.56 121.318L2732.43 121.363ZM2732.16 121.721C2660.45 96.2302 2558.2 59.5839 2485.68 33.376V32.032L2485.55 2.10572L2487.61 4.16651L2352.38 4.92811L2354.71 2.55371L2355.65 43.8144L2353.95 41.6192L2540.19 94.9758L2726.29 148.87L2727.1 149.094V149.945C2725.08 255.314 2723.65 413.01 2722.84 519.678L2721.14 518.021L2737.63 517.573L2735.92 519.23C2737.04 487.96 2737.85 456.69 2739.19 425.464C2741.07 378.648 2745.02 284.748 2746.9 237.977C2748.24 207.334 2749.36 173.824 2751.2 144.569L2732.16 121.766V121.721Z"
        fill="black"
      />
    </g>
    <path d="M2397.85 46.9949H2487.61L2726.92 136.774H2711.96L2397.85 46.9949Z" fill="#B3DBDC" />
    <path
      d="M2397.62 46.9502C2427.37 46.8158 2457.82 45.2926 2487.92 45.2478C2548.17 66.8413 2607.42 91.078 2667.35 113.657C2689.43 121.9 2711.65 129.964 2733.87 137.939L2726.92 138.028L2711.96 138.207C2711.96 138.207 2711.69 138.207 2711.56 138.163C2609.57 110.969 2499.3 76.6973 2397.62 46.995V46.9502ZM2398.07 46.995C2499.34 75.3981 2613.2 104.966 2711.96 135.296L2726.92 135.475L2726.48 137.894C2647.33 106.31 2566.39 79.5197 2486.98 48.6078C2460.51 48.9214 2427.23 46.9502 2398.07 46.995Z"
      fill="black"
    />
    <path d="M2637.43 2005.42L2672.37 2231.79L2863.08 2275.83L2885.39 2097.89L2637.43 2005.42Z" fill="#B7B1AC" />
    <path
      d="M2637.57 2005.6C2648.4 2069.53 2664.84 2168.13 2674.11 2231.57L2672.77 2230.14C2704.48 2237.75 2736.15 2245.73 2767.91 2253.07C2799.66 2260.6 2831.46 2267.72 2863.35 2274.71L2861.96 2275.7C2866.98 2231.26 2877.6 2141.97 2883.02 2097.62L2884.54 2100.13C2814.22 2073.96 2708.47 2033.02 2637.57 2005.64V2005.6ZM2637.3 2005.24C2681.42 2021.64 2781.03 2056.49 2823.67 2072.49L2886.24 2095.65C2887.27 2096.05 2887.9 2097.08 2887.76 2098.16L2876.21 2187.08L2864.25 2275.97C2864.16 2276.59 2863.62 2277.04 2863 2276.95C2831.24 2269.29 2799.48 2261.72 2767.59 2254.55C2735.79 2247.25 2703.86 2240.53 2672.01 2233.5C2671.29 2233.32 2670.8 2232.73 2670.67 2232.06L2661.66 2175.03C2656.2 2138.74 2642.58 2042.74 2637.3 2005.24Z"
      fill="black"
    />
    <path
      d="M2660.68 2229.06L2662.74 2243.26L2868.86 2290.57L2963.37 2275.07L2870.38 2309.21L2675.9 2275.88L2713.13 2439.67L2592.19 2482L2713.13 2459.51L2810.72 2482L2719.31 2478.15L2701.44 2650.22H2968.03L3026.93 2464.22L3014.52 2258.05L2876.61 2278.97L2863.08 2275.88V2275.83L2672.37 2231.79L2660.68 2229.06Z"
      fill="#B7B1AC"
    />
    <path
      d="M2660.81 2229.24L2663.05 2243.22L2662.83 2242.95C2714.33 2254.33 2817.8 2277.49 2869.17 2289.27C2866.71 2289.27 2963.64 2273.64 2963.14 2273.55C2964 2273.41 2964.8 2274 2964.98 2274.85C2965.11 2275.61 2964.67 2276.33 2963.95 2276.59L2871.06 2310.96C2870.74 2311.09 2870.43 2311.09 2870.11 2311.04C2813.77 2301.55 2732.74 2287.57 2675.68 2277.53L2677.61 2275.52L2714.38 2439.4C2714.51 2440.02 2714.15 2440.61 2713.62 2440.83L2592.59 2482.81L2592.15 2481.19C2632.59 2473.94 2672.99 2466.23 2713.39 2458.75L2811.04 2480.97C2812.25 2481.15 2811.98 2483.17 2810.77 2483.03L2719.35 2479.45L2720.65 2478.33L2703.36 2650.45L2701.52 2648.43L2968.12 2648.16L2966.1 2649.64C2965.7 2649.91 3025.81 2460.95 3024.78 2464.39L3018.64 2361.31L3012.82 2258.18L3014.92 2259.88C3014.43 2259.88 2876.92 2280.54 2876.43 2280.36L2862.9 2277.22C2813.37 2265.39 2716.13 2242.86 2660.9 2229.33L2660.81 2229.24ZM2660.5 2228.93C2709.09 2239.77 2821.56 2264.9 2863.35 2274.62L2876.88 2277.67C2874.5 2277.58 3014.65 2256.39 3014.21 2256.25C3015.24 2256.08 3016.22 2256.88 3016.31 2257.96L3022.85 2361.04L3029.08 2464.13C3029.79 2465.11 2969.77 2650 2970 2650.9C2969.73 2651.79 2968.88 2652.37 2967.98 2652.37L2701.39 2652.11C2700.31 2652.11 2699.46 2651.17 2699.55 2650.09L2717.96 2478.1C2718.05 2477.43 2718.63 2476.94 2719.26 2476.98L2810.68 2481.02L2810.41 2483.08L2712.86 2460.41C2674.34 2467.62 2632.01 2475.28 2592.28 2482.9C2591.34 2483.12 2590.85 2481.6 2591.83 2481.28L2712.63 2438.63L2711.87 2440.07L2674.2 2276.37C2673.98 2275.47 2674.56 2274.58 2675.46 2274.35C2733.19 2283.9 2813.81 2297.47 2870.61 2307.51C2866.08 2308.36 2963.64 2273.68 2962.74 2273.73L2963.55 2276.77L2868.99 2292C2868.68 2292 2765.89 2268.22 2765.48 2268.04C2765.35 2268.04 2662.24 2243.85 2662.33 2243.44L2660.45 2229.02L2660.5 2228.93Z"
      fill="black"
    />
    <path d="M2270.55 2339.76L2282.11 2368.48L2376.04 2347.96L2270.55 2339.76Z" fill="#63615E" />
    <path
      d="M2270.73 2339.9C2274.54 2347.96 2280.27 2359.88 2283.72 2367.81L2281.71 2366.73C2297.25 2362.79 2313.02 2359.88 2328.87 2357.28C2344.68 2354.55 2360.18 2350.42 2375.72 2346.35L2375.95 2349.66C2341.32 2348.32 2305.31 2344.11 2270.78 2339.94L2270.73 2339.9ZM2270.38 2339.63C2305.27 2340.7 2341.5 2342.32 2376.17 2346.3C2378.1 2346.3 2378.32 2349.3 2376.39 2349.62C2360.58 2352.4 2344.77 2355.08 2329.27 2359.2C2313.78 2363.46 2298.19 2367.36 2282.42 2370.27C2281.57 2370.45 2280.77 2369.96 2280.45 2369.2C2278.48 2364.13 2276.82 2358.98 2275.12 2354.01L2270.38 2339.67V2339.63Z"
      fill="black"
    />
    <path
      d="M2462.84 2330.89L2675.91 2275.88L2870.39 2309.21L2963.37 2275.07L2868.86 2290.57L2662.74 2243.26L2462.84 2330.89Z"
      fill="#63615E"
    />
    <path
      d="M2463.06 2330.8C2530.83 2313.06 2607.74 2291.29 2675.41 2273.95C2739.96 2284.61 2806.96 2297.61 2870.16 2308.58C2893.23 2300.07 2939.95 2282.69 2962.97 2274L2963.55 2276.28C2962.83 2276.28 2869.13 2292.77 2868.42 2292.45C2819.46 2281.07 2706.81 2256.79 2663.63 2245.37C2600.93 2272.38 2525.72 2303.65 2463.06 2330.85V2330.8ZM2462.66 2330.94C2525.23 2303.21 2599.32 2269.07 2661.84 2241.2C2712.68 2251.55 2820.8 2277.4 2868.55 2288.65L2963.19 2273.91C2963.82 2273.82 2964.45 2274.22 2964.58 2274.89C2964.67 2275.47 2964.31 2276.01 2963.82 2276.19C2940.3 2284.43 2893.77 2301.59 2870.3 2309.84C2806.87 2298.81 2736.73 2288.6 2676.44 2277.76C2608.72 2295.37 2530.7 2313.64 2462.7 2330.94H2462.66Z"
      fill="black"
    />
    <path
      d="M2417.38 2100.98L2440.08 2222.88L2441.96 2328.88L2660.68 2229.06L2672.37 2231.79L2637.43 2005.42L2417.38 2100.98Z"
      fill="#92908E"
    />
    <path
      d="M2417.6 2101.07C2425.03 2136.91 2435.07 2187.49 2441.83 2222.83C2442.77 2253.88 2443.35 2298.14 2443.85 2328.83L2441.2 2327.17C2506.01 2298.14 2597.03 2258.14 2660.18 2227.99C2660.41 2227.9 2660.72 2227.85 2660.94 2227.9L2672.68 2230.54L2671.11 2231.97L2652.79 2118.9C2648.22 2090.76 2639.44 2033.91 2635.1 2005.73L2638.33 2007.48C2569.39 2037.32 2486.84 2071.99 2417.55 2101.02L2417.6 2101.07ZM2417.2 2100.84C2485.77 2069.98 2567.56 2033.33 2636.49 2003.27C2637.83 2002.64 2639.49 2003.54 2639.71 2005.02L2648.49 2061.6C2654 2097.44 2668.25 2194.43 2673.66 2231.57C2673.8 2232.47 2672.99 2233.23 2672.1 2233L2660.41 2230.18L2661.17 2230.09C2597.48 2258.18 2506.64 2300.47 2442.77 2330.53C2441.61 2331.12 2440.08 2330.17 2440.13 2328.83C2439.19 2298.37 2438.92 2253.79 2438.38 2222.83C2431.89 2188.88 2423.47 2136.37 2417.24 2100.8L2417.2 2100.84Z"
      fill="black"
    />
    <path
      d="M2660.68 2229.06L2441.97 2328.88V2328.92L2424.05 2337.07L2264.42 2324.71L2282.11 2368.48L2270.55 2339.76L2376.04 2347.96L2282.11 2368.48L2338.86 2509.15V2650.22H2701.44L2719.31 2478.15L2810.73 2482L2713.13 2459.51L2592.19 2482L2713.13 2439.67L2675.91 2275.88L2462.84 2330.89L2662.74 2243.26L2660.68 2229.06Z"
      fill="#92908E"
    />
    <path
      d="M2660.54 2229.29C2597.43 2258.72 2483.13 2311.45 2424.63 2338.33C2423.78 2338.55 2265.13 2326.5 2264.28 2326.46L2266.07 2324.04L2283.86 2367.76L2280.36 2369.2L2268.76 2340.52C2268.22 2339.22 2269.3 2337.7 2270.73 2337.84L2376.21 2346.21C2378.32 2346.35 2378.36 2349.39 2376.44 2349.8L2282.42 2370.09L2283.54 2367.99L2339.93 2508.79C2339.98 2509.33 2339.71 2649.78 2339.62 2650.31C2394.94 2648.21 2461.18 2650.27 2520.13 2649.33L2701.44 2648.88L2700.05 2650.14L2717.38 2478.01C2717.47 2476.98 2718.37 2476.22 2719.4 2476.27L2810.81 2479.99L2810.28 2484.02L2712.68 2461.62C2717.34 2461.44 2591.39 2484.11 2592.64 2484.15C2590.04 2484.6 2589.1 2480.88 2591.52 2480.03L2712.41 2437.65L2710.98 2440.2C2701.57 2399.39 2683.12 2317.18 2673.94 2276.37L2676.44 2277.89L2463.24 2332.24C2461.72 2332.73 2460.87 2330.31 2462.39 2329.73L2662.69 2243.04L2662.51 2243.35L2660.63 2229.29H2660.54ZM2660.77 2228.88L2663.05 2243.22C2663.05 2243.22 2663.01 2243.49 2662.87 2243.53L2463.42 2332.06L2462.57 2329.55L2675.46 2273.86C2676.58 2273.55 2677.7 2274.26 2677.97 2275.38C2687.28 2316.2 2706.18 2398.32 2715.32 2439.13C2715.59 2440.25 2714.96 2441.32 2713.89 2441.68L2592.95 2483.93L2591.83 2479.81C2592.69 2479.81 2712.81 2457.05 2713.62 2457.41L2811.22 2479.9C2812.34 2480.16 2813.01 2481.24 2812.74 2482.36C2812.52 2483.35 2811.62 2483.97 2810.68 2483.93L2719.26 2479.99L2721.28 2478.24L2702.91 2650.22C2702.82 2650.94 2702.24 2651.48 2701.53 2651.48L2520.21 2651.03C2459.84 2650.22 2397.62 2652.06 2338.1 2650.05C2338.37 2650.22 2337.43 2507.4 2337.78 2509.37L2280.68 2368.84C2280.32 2367.94 2280.86 2366.91 2281.8 2366.73L2375.72 2345.99L2375.95 2349.57L2270.42 2341.55L2272.39 2338.87L2283.86 2367.63C2284.8 2369.91 2281.26 2371.3 2280.36 2369.06L2262.8 2325.25C2262.31 2324.08 2263.3 2322.69 2264.6 2322.83C2264.37 2323.1 2426.51 2335.37 2423.51 2335.73C2481.96 2309.12 2597.39 2257.15 2660.77 2228.79V2228.88Z"
      fill="black"
    />
    <path
      d="M2643.3 2236.99L2479.1 2287.08L2462.84 2243.26L2493.03 2209.98L2453.52 2217.73L2421.18 2099.32L2417.38 2100.98L2440.08 2222.88L2441.96 2328.88L2643.3 2236.99Z"
      fill="#63615E"
    />
    <path
      d="M2643.12 2237.08C2590.04 2253.52 2532.58 2272.83 2479.68 2289C2478.65 2289.32 2477.57 2288.78 2477.21 2287.79L2461.09 2243.93C2460.82 2243.26 2461 2242.55 2461.45 2242.05L2492 2209.08L2493.29 2211.37L2453.7 2218.67C2453.25 2218.76 2452.76 2218.44 2452.63 2218C2443.53 2183.54 2429.92 2134.4 2420.11 2099.63L2421.59 2100.35L2417.82 2102.01L2418.49 2100.75C2418.49 2101.11 2442.23 2222.48 2442.19 2222.83L2443.35 2275.83L2444.25 2328.83L2440.98 2326.77C2457.64 2319.24 2474.12 2311.63 2490.83 2304.32C2536.11 2284.52 2598.06 2257.42 2643.07 2237.08H2643.12ZM2643.52 2236.9C2598.87 2257.46 2538.17 2286.36 2493.74 2307.46C2476.9 2315.44 2459.84 2323.19 2442.91 2330.94C2441.74 2331.47 2440.4 2330.94 2439.86 2329.82C2439.73 2329.5 2439.64 2329.19 2439.64 2328.88L2438.65 2275.88L2437.93 2222.88C2438.11 2224.63 2416.26 2100.75 2416.26 2101.16C2416.17 2100.62 2416.43 2100.13 2416.93 2099.9L2420.73 2098.29C2421.27 2098.07 2421.9 2098.29 2422.17 2098.83C2431.57 2134.04 2444.56 2182.92 2454.42 2217.41L2453.34 2216.74L2492.76 2208.54C2493.52 2208.41 2494.24 2208.86 2494.41 2209.62C2494.5 2210.07 2494.37 2210.51 2494.06 2210.83L2464.23 2244.43L2464.58 2242.55L2480.93 2286.32L2478.47 2285.11C2532.44 2268.62 2589.46 2253.12 2643.48 2236.86L2643.52 2236.9Z"
      fill="black"
    />
    <path
      d="M2611.59 2048.79L2623.23 2134.04L2507.76 2167.15L2502.34 2140.63L2530.34 2134.04L2498.49 2130.9L2487.61 2095.29L2611.59 2048.79Z"
      fill="#FFFBE4"
    />
    <path
      d="M2611.72 2048.56L2618.66 2090.58C2621.04 2104.79 2623.05 2119.35 2625.2 2133.77C2625.34 2134.76 2624.71 2135.65 2623.81 2135.92C2595.06 2144.52 2565.81 2151.47 2536.88 2159.62C2527.2 2162.22 2517.62 2165.18 2508.03 2167.95C2507.58 2168.09 2507.09 2167.82 2507 2167.33L2501.13 2140.9C2501 2140.22 2501.4 2139.6 2502.03 2139.42C2502.03 2139.42 2529.93 2132.29 2529.98 2132.29L2530.2 2135.83L2498.27 2133.1C2497.37 2133.01 2496.7 2132.43 2496.43 2131.62C2492.76 2119.75 2488.95 2107.92 2485.37 2096.01C2485.01 2094.8 2485.63 2093.54 2486.8 2093.09C2522.27 2079.56 2575.48 2061.24 2611.72 2048.61V2048.56ZM2611.5 2048.97C2576.11 2063.3 2523.71 2084.36 2488.46 2097.48L2489.89 2094.57C2493.56 2106.44 2496.97 2118.41 2500.55 2130.28L2498.71 2128.75L2530.56 2132.2C2532.58 2132.29 2532.75 2135.38 2530.78 2135.74L2502.66 2141.79L2503.6 2140.31L2508.57 2166.92L2507.54 2166.3C2517.17 2163.56 2526.84 2160.97 2536.43 2158.05C2565.23 2149.63 2593.71 2140.04 2622.69 2132.07L2621.3 2134.22C2618.17 2109.85 2614.09 2073.78 2611.5 2048.88V2048.97Z"
      fill="black"
    />
    <path d="M2861.61 2335.55V2401.68L2724.24 2384.11L2701.48 2304.55L2861.61 2335.55Z" fill="#FFFBE4" />
    <path
      d="M2861.79 2335.37C2862.5 2356.83 2863.31 2379.23 2863.4 2401.63C2863.4 2402.62 2862.59 2403.42 2861.61 2403.42C2815.56 2397.96 2768.89 2390.79 2723.39 2384.29C2715.32 2357.91 2707.04 2331.56 2699.38 2305.09C2699.06 2303.97 2699.69 2302.76 2700.86 2302.44C2728.13 2306.79 2755.5 2312.79 2782.6 2318.21C2802.35 2322.38 2842.08 2330.94 2861.74 2335.37H2861.79ZM2861.43 2335.64C2823.98 2329.46 2777.72 2321.57 2740.67 2314.45C2727.42 2312.03 2714.29 2309.25 2701.03 2306.61L2703.5 2303.92C2710.98 2330.49 2717.88 2357.19 2724.95 2383.84L2724.24 2383.26C2769.97 2389.89 2816.06 2393.12 2861.79 2399.88L2859.77 2401.68C2859.91 2380.04 2860.67 2358.31 2861.38 2335.68L2861.43 2335.64Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2458_14"
        x1={2968}
        y1={2209.04}
        x2={2786.63}
        y2={2251.46}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5B3A00" />
        <stop offset={1} stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
))`
  flex-shrink: 0;
  margin: 60px auto;
  width: 919px;
  height: 820px;

  .shaking {
    transform-origin: 1497px 1562px;
    animation: ${shakeAnimation} 0.2s infinite;
  }
`;

export default Bronze;

import styled from 'styled-components';
import React from 'react';

const ProfileIcon = styled((props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none" {...props}>
    <path
      d="M27.32 30.9903C32.9588 30.9903 37.53 26.4191 37.53 20.7803C37.53 15.1415 32.9588 10.5703 27.32 10.5703C21.6812 10.5703 17.11 15.1415 17.11 20.7803C17.11 26.4191 21.6812 30.9903 27.32 30.9903Z"
      fill="#262626"
    />
    <path
      d="M27.39 0.340073C12.83 0.340073 0.97998 12.1901 0.97998 26.7501C0.97998 34.9601 4.75001 42.3001 10.64 47.1501C15.2 50.9001 21.04 53.1601 27.39 53.1601C33.74 53.1601 39.1 51.0801 43.58 47.5901C49.79 42.7501 53.8 35.2101 53.8 26.7401C53.8 12.1801 41.95 0.330078 27.39 0.330078V0.340073ZM43.47 45.1001C43.03 41.5201 41.18 38.3701 38.49 36.2301C36.19 34.4001 33.28 33.3001 30.11 33.3001H24.1C21.09 33.3001 18.31 34.2901 16.06 35.9601C13.3 38.0201 11.37 41.1101 10.81 44.6601C5.99 40.2001 2.96997 33.8201 2.96997 26.7501C2.96997 13.2901 13.92 2.34007 27.38 2.34007C40.84 2.34007 51.79 13.2901 51.79 26.7501C51.79 34.0501 48.56 40.6201 43.46 45.0901L43.47 45.1001Z"
      fill="#262626"
    />
  </svg>
))`
  margin-left: 34px;
`;

export default ProfileIcon;

import styled from 'styled-components';

const BonusIcon = styled((props) => (
  <svg {...props} width="104" height="140" viewBox="0 0 104 140" fill="none">
    <path
      d="M95.33 3.03978C90.31 0.379778 85.29 0.189778 80.41 2.44978C64.1 10.0098 54.33 44.4998 51.63 55.2998C48.93 44.4998 39.16 10.0098 22.85 2.44978C17.97 0.189778 12.95 0.389778 7.93001 3.03978C1.95001 6.19978 0.590014 10.5498 0.500014 13.6398C-0.0299857 31.8298 43.17 58.5498 48.1 61.5298C48.56 61.8098 49.07 61.9398 49.58 61.9398C50.16 61.9398 50.75 61.7598 51.24 61.4098C51.38 61.3098 51.5 61.1998 51.61 61.0798C51.73 61.1998 51.85 61.3098 51.98 61.4098C52.48 61.7598 53.06 61.9398 53.64 61.9398C54.15 61.9398 54.67 61.7998 55.12 61.5298C60.05 58.5398 103.25 31.8298 102.72 13.6398C102.63 10.5498 101.27 6.19978 95.29 3.03978H95.33ZM6.23001 13.8098C6.27001 12.3298 6.77001 10.1298 10.62 8.09978C12.48 7.11978 14.28 6.62977 16.06 6.62977C17.54 6.62977 19 6.96978 20.46 7.64978C27.09 10.7198 33.72 20.5698 39.66 36.1498C42.05 42.4098 43.87 48.4598 45.08 52.8598C27.9 41.5898 5.95001 23.8098 6.25001 13.8198L6.23001 13.8098ZM58.2 52.8498C59.41 48.4498 61.23 42.3998 63.62 36.1398C69.55 20.5698 76.19 10.7098 82.82 7.63978C84.28 6.95978 85.74 6.61978 87.22 6.61978C89 6.61978 90.8 7.10977 92.66 8.08977C96.5 10.1198 97 12.3198 97.05 13.7998C97.34 23.7798 75.39 41.5698 58.22 52.8398L58.2 52.8498Z"
      fill="#262626"
      stroke="#0A0A09"
      strokeWidth="0.97"
      strokeMiterlimit="10"
    />
    <path
      d="M56.32 58.8998C56.32 57.1898 54.31 55.7998 51.83 55.7998C49.35 55.7998 47.34 57.1898 47.34 58.8998C47.34 60.6098 49.35 61.9998 51.83 61.9998C54.31 61.9998 56.32 60.6098 56.32 58.8998Z"
      fill="#98E703"
      stroke="black"
      strokeWidth="0.93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.61 77.9697H9.17004L12.78 133.39C12.78 133.39 21.57 138.77 49.39 138.77C77.21 138.77 86 133.39 86 133.39L89.61 77.9697Z"
      fill="#98E703"
      stroke="black"
      strokeWidth="0.97"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M97.89 66.4196C97.89 63.3196 77.27 60.8096 51.82 60.8096C26.37 60.8096 5.75 63.3196 5.75 66.4196V82.0296C5.75 83.1596 6.72 84.0396 7.85 83.9296C12.23 83.4696 24.31 82.5596 51.82 82.5596C79.33 82.5596 89.18 83.4296 92.83 83.8996C93.84 84.0296 94.77 83.3496 94.95 82.3596L97.89 66.4296V66.4196Z"
      fill="#8CC905"
      stroke="black"
      strokeWidth="0.97"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.69 60.8096H48.97V139.38H54.69V60.8096Z"
      fill="#252623"
      stroke="black"
      strokeWidth="0.93"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M44.38 61.5198C37.19 62.4998 15.54 65.5298 11.32 67.7898C10.86 68.0298 10.58 68.2898 10.41 68.4398C7.51002 71.0498 7.41002 77.1998 10.15 79.6398C11.5 80.8398 13.26 80.8998 13.81 80.9098C19.44 81.0898 31.91 81.6998 48.56 82.4098C34.44 82.7798 20.32 83.1498 6.19002 83.5198C6.11002 77.6298 6.04002 71.7398 5.96002 65.8498C7.20002 65.4098 8.91002 64.8498 10.96 64.2798C12.4 63.8798 17.68 62.4498 25.07 61.6698C29.86 61.1598 36.45 60.8298 44.37 61.5098L44.38 61.5198Z"
        fill="#75A311"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M58.1 61.2998C72.91 63.6098 84.19 65.6298 89.46 66.7898C90.21 66.9498 91.93 67.3598 92.94 68.7898C94.75 71.3598 93.5 76.1298 90.76 78.6298C88.24 80.9298 84.85 80.9598 83.31 80.9798C76.22 81.0798 67.11 81.6198 55.13 82.5498C68.2 82.8398 81.26 83.1298 94.33 83.4198C95.52 77.7498 96.7 72.0798 97.89 66.4098C97.48 66.0598 96.83 65.5598 95.99 65.0398C93.42 63.4598 90.75 62.8298 85.44 62.2998C80.97 61.8498 78.78 61.8098 78.78 61.8098C73.27 61.7198 66.64 61.5498 58.1 61.2998Z"
        fill="#75A311"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M52.52 53.1199C58.96 23.2099 71.95 2.03991 86.32 1.20991C88.44 1.08991 90.82 1.38991 93.46 2.42991C90.09 2.84991 84.74 3.99991 79.44 7.56991C78.73 8.04991 75.72 10.1099 72.56 13.9299C63.26 25.1499 53.63 50.2099 52.53 53.1299L52.52 53.1199Z"
        fill="#75A311"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M54.27 60.7996C53.1 61.3196 51.85 62.1495 51.07 63.4995C51.07 63.4995 50.81 63.9095 50.66 64.3895C50.32 65.4295 51.24 127.76 50.92 132.98C50.88 133.6 50.68 135.82 52.03 137.55C52.87 138.63 54 139.13 54.69 139.37C54.04 139.26 53.32 139.17 52.55 139.12C51.35 139.05 50.28 139.09 49.35 139.19C49.31 122.86 49.26 106.53 49.22 90.1896C49.05 80.4296 48.87 70.6795 48.7 60.9195C50.56 60.8795 52.42 60.8296 54.27 60.7896V60.7996Z"
        fill="#75A311"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M45.3 82.6899C38.32 83.6599 32.8 85.3399 28.97 86.7399C21.14 89.6099 19.34 91.7099 18.52 92.8799C17.06 94.9599 16.56 97.1799 16.56 104.51C16.56 117.94 16.63 124.74 20.35 129.2C22.74 132.06 25.39 133.29 29.23 135.08C37.34 138.85 44.84 139.3 49.35 139.2C40.87 139.25 30.95 138.4 20.21 135.61C17.61 134.94 15.14 134.19 12.78 133.4C11.77 116.8 10.77 100.21 9.76001 83.6099C14.93 83.4099 20.16 83.2299 25.44 83.0899C32.14 82.9099 38.76 82.7799 45.3 82.6999V82.6899Z"
        fill="#75A311"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M55.14 82.5596C62.02 83.7396 67.57 85.3696 71.56 86.7396C79.97 89.6196 81.66 91.4096 82.54 92.6196C83.32 93.6996 84.83 96.2096 83.98 106.6C83.01 118.47 82.4 125.84 76.79 130.64C74.67 132.46 72.41 133.33 67.91 135.08C62.68 137.11 58.07 138.03 54.84 138.48C60.48 138.61 67.45 138.23 75.22 136.52C79.21 135.64 82.82 134.55 86.01 133.39C87.21 116.75 88.4 100.11 89.6 83.4696L55.15 82.5596H55.14Z"
        fill="#75A311"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M11.19 1.5498C9.48002 2.0298 3.79002 3.85981 1.52002 8.99981C0.130023 12.1398 0.640021 15.0998 0.870021 16.4498C2.99002 28.8898 27.3 47.2498 29.09 48.5898C18.27 38.0298 11.58 29.0998 7.66002 23.2398C5.46002 19.9598 3.12002 16.1098 3.87002 11.4798C4.75002 6.09981 9.31002 2.7698 11.19 1.5498Z"
        fill="#75A311"
      />
    </g>
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        d="M55.81 58.06C55.56 57.66 54.81 56.6 53.39 56.07C53.25 56.02 51.26 55.31 49.41 56.36C49 56.59 48.36 56.97 47.97 57.76C47.5 58.73 47.73 59.66 47.81 59.95C48.23 59.43 49.11 58.46 50.55 57.89C53.06 56.9 55.32 57.84 55.81 58.05V58.06Z"
        fill="#75A311"
      />
    </g>
  </svg>
))`
  margin-right: 30px;
`;

export default BonusIcon;

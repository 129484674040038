import styled from 'styled-components';
import React from 'react';

const BoostIcon = styled((props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="67"
    height="67"
    viewBox="0 0 67 67"
    fill="none"
    {...props}
  >
    <path
      d="M65.9675 3.37C65.8275 2.93 65.6075 2.51 65.3175 2.16C64.8375 1.56 64.1975 1.13 63.4675 0.91C61.8075 0.31 59.9375 0 57.9275 0C52.2375 0 45.7675 2.51 42.1775 6.1L36.6375 11.64C36.3475 11.59 36.0575 11.56 35.7575 11.57C35.6675 11.57 35.5775 11.58 35.4975 11.59L16.3375 13.93C15.1475 14.08 14.0975 14.78 13.5175 15.83L6.17749 29.06C5.50749 30.27 5.55749 31.75 6.30749 32.91C7.03749 34.04 8.30749 34.68 9.63749 34.62C9.67749 34.62 9.71749 34.62 9.75749 34.62L17.7975 33.95C17.9775 34.35 18.2275 34.72 18.5375 35.03L31.8775 48.37C32.1075 48.6 32.3575 48.78 32.6275 48.94L31.9275 57.48C31.8175 58.86 32.4675 60.18 33.6275 60.93C34.1875 61.29 34.8275 61.49 35.4675 61.52C36.1575 61.55 36.8475 61.4 37.4775 61.05L50.6975 53.71C51.7475 53.13 52.4575 52.08 52.5975 50.89L54.9175 31.73C54.9575 31.36 54.9475 31 54.8875 30.65L60.7975 24.74C65.4275 20.11 68.5675 10.47 65.9575 3.37H65.9675ZM56.4675 19.87C55.1675 21.17 53.4575 21.82 51.7475 21.82C50.0375 21.82 48.3375 21.17 47.0375 19.87C44.4375 17.27 44.4375 13.04 47.0375 10.44C48.2975 9.18 49.9675 8.49 51.7475 8.49C53.5275 8.49 55.1975 9.19 56.4575 10.45C57.7175 11.7 58.4075 13.38 58.4075 15.16C58.4075 16.94 57.7175 18.62 56.4575 19.87H56.4675Z"
      fill="#262626"
    />
    <path
      d="M4.98749 63.8997C4.50749 63.8997 4.0275 63.7197 3.6675 63.3497C2.9375 62.6197 2.9375 61.4297 3.6675 60.6997L19.5975 44.7697C20.3275 44.0397 21.5175 44.0397 22.2475 44.7697C22.9775 45.4997 22.9775 46.6897 22.2475 47.4197L6.3175 63.3497C5.9475 63.7197 5.47749 63.8997 4.9975 63.8997H4.98749Z"
      fill="#262626"
    />
    <path
      d="M1.8675 52.7198C1.3875 52.7198 0.9075 52.5398 0.5475 52.1698C-0.1825 51.4398 -0.1825 50.2498 0.5475 49.5198L12.4475 37.6198C13.1775 36.8898 14.3675 36.8898 15.0975 37.6198C15.8275 38.3498 15.8275 39.5398 15.0975 40.2698L3.1975 52.1698C2.8375 52.5398 2.3575 52.7198 1.8775 52.7198H1.8675Z"
      fill="#262626"
    />
    <path
      d="M16.0675 66.92C15.5875 66.92 15.1075 66.74 14.7475 66.37C14.0175 65.64 14.0175 64.45 14.7475 63.72L26.6475 51.82C27.3775 51.09 28.5675 51.09 29.2975 51.82C30.0275 52.55 30.0275 53.74 29.2975 54.47L17.3975 66.37C17.0375 66.74 16.5575 66.92 16.0775 66.92H16.0675Z"
      fill="#262626"
    />
  </svg>
))``;

export default BoostIcon;
